import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import styles from './OrganiseStakeholders.module.css';
import {
	Plan,
	StakeholderGroup,
	Size,
	Segment,
	SegmentGroup,
	Status,
} from '../../../types';
import AppButton from '../../../components/Button/AppButton';
import AppIcon from '../../../components/AppIcon/AppIcon';
import Icon from '@mdi/react';
import {
	mdiAccountGroup,
	mdiAccountMultiplePlus,
	mdiCog,
	mdiContentSave,
	mdiDelete,
	mdiPlusBox,
} from '@mdi/js';
import SidePanel from '../../../components/SidePanel/SidePanel';
import AppInput from '../../../components/AppInput/AppInput';
import AppCheckbox from '../../../components/AppCheckbox/AppCheckbox';
import slice, { mapSliced, StateUpdater } from '../../../StateManagement';
import { MoreInfoButton } from '../TextBox/TextBox';
import { SegmentTemplate } from '../../../segments';
import { setStatus } from '../../../components/Status/Status';
import { useAuthenticatedFetch } from '../../../auth';

const API_URL = process.env.REACT_APP_BACKEND;

const StakeholderGroupManagement: FC<{
	plan: Plan;
	setPlan: Dispatch<SetStateAction<Plan>>;
	template: SegmentTemplate;
}> = ({ plan, setPlan, template }) => {
	const addGroup = (newValue: StakeholderGroup) => {
		setStatus(
			setPlan,
			SegmentGroup.Initiative,
			Segment.OrganiseStakeholders,
			Status.Done
		);
		return slice(
			setPlan,
			'stakeholderGroups'
		)([...plan.stakeholderGroups, newValue]);
	};
	return (
		<div className={styles.ProcessGoalDescription}>
			<div>
				<h2 className="h1-style">{template.title}</h2>
				<MoreInfoButton tooltip={true} onlyIcon={false} inline={true}>
					<p>
						Organiseer de belanghebbende op basis van domein, mandaat en
						impact. Creeer nieuwe (tijdelijke) groepen waar dit waardevol is
					</p>
				</MoreInfoButton>
				<table role="presentation">
					<thead>
						<tr>
							<th scope="col">Groepsnaam</th>
							<th scope="colgroup" colSpan={2}>
								Gekoppelde belanghebbenden
							</th>
						</tr>
					</thead>
					<tbody>
						{mapSliced(
							plan.stakeholderGroups,
							slice(setPlan, 'stakeholderGroups'),
							(props, index: number) => (
								<tr key={index}>
									<td>
										<AppIcon
											icon={
												<Icon
													path={mdiAccountGroup}
													size={1}
													className={styles.TableIcon}
												/>
											}
										>
											{props.value.name}
										</AppIcon>
									</td>
									<td>
										{props.value.stakeholders
											.map((stakeholder) => stakeholder.description)
											.join(', ')}
									</td>
									<td className={styles.TableButtonsContainer}>
										<EditSidePanelButton
											plan={plan}
											stakeholderGroup={props.value}
											setValue={props.setValue}
										/>
										<DeleteSidePanelButton
											plan={plan}
											stakeholderGroup={props.value}
											remove={props.remove}
										/>
									</td>
								</tr>
							)
						)}
					</tbody>
				</table>
				<AddSidePanelButton plan={plan} addGroup={addGroup} />
			</div>
		</div>
	);
};

const AddSidePanelButton: FC<{
	plan: Plan;
	addGroup: (newValue: StakeholderGroup) => void;
}> = ({ plan, addGroup }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<AddSidePanel
				plan={plan}
				open={open}
				setOpen={setOpen}
				addGroup={addGroup}
			/>
			<AppButton onClick={() => setOpen(true)}>
				<AppIcon
					icon={<Icon path={mdiAccountMultiplePlus} size={1} />}
					size={Size.Large}
					iconRight
				>
					GROEP AANMAKEN
				</AppIcon>
			</AppButton>
		</>
	);
};

//Todo @Michiel or @TImon this should be refactored to 1 upsert method instead of 2 almost identical ones
const AddSidePanel: FC<{
	plan: Plan;
	open: boolean;
	setOpen: (open: boolean) => void;
	addGroup: (newValue: StakeholderGroup) => void;
}> = ({ plan, open, setOpen, addGroup }) => {
	const authenticatedFetch = useAuthenticatedFetch();
	const [stakeholderGroup, setStakeholderGroup] = useState<StakeholderGroup>({
		stakeholderGroupId: 0,
		name: '',
		stakeholders: [],
	});

	useEffect(() => {
		setStakeholderGroup({
			stakeholderGroupId: 0,
			name: '',
			stakeholders: [],
		});
	}, [plan]);

	const addStakeholderGroup = () => {
		authenticatedFetch(`${API_URL}/plan/${plan.planId}/stakeholderGroup`, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify(stakeholderGroup),
		})
			.then((r) => r.json())
			.then((r) => addGroup(r))
			.then(() => setOpen(false));
	};

	return (
		<SidePanel title={'Nieuwe groep toevoegen'} open={open} setOpen={setOpen}>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					addStakeholderGroup();
				}}
			>
				<AppInput
					autoFocus
					label={'Naam'}
					value={stakeholderGroup.name}
					onChange={(event) => {
						setStakeholderGroup({
							...stakeholderGroup,
							name: event.target.value,
						});
					}}
					required
				/>
				{plan.stakeholders.map((stakeholder, i) => (
					<AppCheckbox
						key={i}
						label={i == 0 ? 'Belanghebbenden' : ''}
						inputSize={Size.Large}
						checked={stakeholderGroup.stakeholders.some(
							(s) => s.stakeholderId == stakeholder.stakeholderId
						)}
						onCheck={(checked: boolean) => {
							const stakeholders = checked
								? [...stakeholderGroup.stakeholders, stakeholder]
								: stakeholderGroup.stakeholders.filter(
										(s) => s.stakeholderId != stakeholder.stakeholderId
									);
							setStakeholderGroup({
								...stakeholderGroup,
								stakeholders: stakeholders,
							});
						}}
					>
						{stakeholder.description}{' '}
					</AppCheckbox>
				))}
				<AppButton type={'submit'}>
					<AppIcon icon={<Icon path={mdiPlusBox} size={1} />} iconRight>
						Toevoegen
					</AppIcon>
				</AppButton>
			</form>
		</SidePanel>
	);
};

const EditSidePanelButton: FC<{
	plan: Plan;
	stakeholderGroup: StakeholderGroup;
	setValue: StateUpdater<StakeholderGroup>;
}> = ({ plan, stakeholderGroup, setValue }) => {
	const [open, setOpen] = useState(false);
	return (
		<>
			<EditSidePanel
				plan={plan}
				stakeholderGroup={stakeholderGroup}
				open={open}
				setOpen={setOpen}
				setValue={setValue}
			/>
			<div onClick={() => setOpen(true)}>
				<Icon path={mdiCog} size={1} className={styles.TableActionButton} />
			</div>
		</>
	);
};

const EditSidePanel: FC<{
	plan: Plan;
	stakeholderGroup: StakeholderGroup;
	open: boolean;
	setOpen: (open: boolean) => void;
	setValue: StateUpdater<StakeholderGroup>;
}> = ({ plan, stakeholderGroup, open, setOpen, setValue }) => {
	const [editedStakeholderGroup, setEditedStakeholderGroup] =
		useState(stakeholderGroup);

	const authenticatedFetch = useAuthenticatedFetch();
	const editStakeholder = () => {
		authenticatedFetch(`${API_URL}/plan/${plan.planId}/stakeholderGroup`, {
			method: 'PUT',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify(editedStakeholderGroup),
		})
			.then((r) => r.json())
			.then((r) => setValue(r))
			.then(() => setOpen(false));
	};

	useEffect(() => {
		setEditedStakeholderGroup(stakeholderGroup);
	}, [stakeholderGroup]);

	return (
		<SidePanel title={'Groep aanpassen'} open={open} setOpen={setOpen}>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					editStakeholder();
				}}
			>
				<AppInput
					autoFocus
					label={'Naam'}
					placeholder={stakeholderGroup.name}
					value={editedStakeholderGroup.name}
					onChange={(event) => {
						setEditedStakeholderGroup({
							...editedStakeholderGroup,
							name: event.target.value,
						});
					}}
					required
				/>
				{plan.stakeholders.map((stakeholder, i) => (
					<AppCheckbox
						key={i}
						label={i == 0 ? 'Belanghebbenden' : ''}
						inputSize={Size.Large}
						checked={editedStakeholderGroup.stakeholders.some(
							(s) => s.stakeholderId == stakeholder.stakeholderId
						)}
						onCheck={(checked: boolean) => {
							const stakeholders = checked
								? [...editedStakeholderGroup.stakeholders, stakeholder]
								: editedStakeholderGroup.stakeholders.filter(
										(s) => s.stakeholderId != stakeholder.stakeholderId
									);
							setEditedStakeholderGroup({
								...editedStakeholderGroup,
								stakeholders: stakeholders,
							});
						}}
					>
						{stakeholder.description}{' '}
					</AppCheckbox>
				))}
				<AppButton type={'submit'}>
					<AppIcon icon={<Icon path={mdiContentSave} size={1} />} iconRight>
						Opslaan
					</AppIcon>
				</AppButton>
			</form>
		</SidePanel>
	);
};

const DeleteSidePanelButton: FC<{
	plan: Plan;
	stakeholderGroup: StakeholderGroup;
	remove: () => void;
}> = ({ plan, stakeholderGroup, remove }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<DeleteSidePanel
				plan={plan}
				stakeholderGroup={stakeholderGroup}
				open={open}
				setOpen={setOpen}
				remove={remove}
			/>
			<div onClick={() => setOpen(true)}>
				<Icon path={mdiDelete} size={1} className={styles.TableActionButton} />
			</div>
		</>
	);
};

const DeleteSidePanel: FC<{
	plan: Plan;
	stakeholderGroup: StakeholderGroup;
	open: boolean;
	setOpen: (open: boolean) => void;
	remove: () => void;
}> = ({ plan, stakeholderGroup, open, setOpen, remove }) => {
	const authenticatedFetch = useAuthenticatedFetch();
	const deleteStakeholder = () => {
		authenticatedFetch(
			`${API_URL}/plan/${plan.planId}/stakeholderGroup/${stakeholderGroup.stakeholderGroupId}`,
			{
				method: 'DELETE',
			}
		)
			.then(() => remove())
			.then(() => setOpen(false));
	};

	return (
		<SidePanel title={'Groep verwijderen'} open={open} setOpen={setOpen}>
			<div>
				Weet u zeker dat u &ldquo;{stakeholderGroup.name}&rdquo; wilt
				verwijderen?
			</div>
			<AppButton onClick={() => deleteStakeholder()}>Verwijderen</AppButton>
		</SidePanel>
	);
};

export default StakeholderGroupManagement;
