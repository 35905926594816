import styles from './Loader.module.css';
import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';

const Loader = (): JSX.Element => (
	<div className={styles.Loader}>
		<Icon path={mdiLoading} size={1.5} spin />
	</div>
);

export default Loader;
