import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import styles from './TextBox.module.css';
import { Plan, Size, Status, Text } from '../../../types';
import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor';
import AppIcon from '../../../components/AppIcon/AppIcon';
import AppButton from '../../../components/Button/AppButton';
import ToolTip from '../../../components/ToolTip/ToolTip';
import SidePanel from '../../../components/SidePanel/SidePanel';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';
import slice from '../../../StateManagement';
import { SegmentTemplate } from '../../../segments';
import { setStatus } from '../../../components/Status/Status';
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useAuthenticatedFetch } from '../../../auth';

const API_URL = process.env.REACT_APP_BACKEND;

const TextBox: FC<{
	plan: Plan;
	setPlan: React.Dispatch<React.SetStateAction<Plan>>;
	template: SegmentTemplate;
}> = ({
	plan,
	setPlan,
	template: { segmentGroup, segment, title, header, ...editor },
}) => {
	useEffect(() => {
		if (editor.type !== 'textBox') return;
		const texts = plan.texts.filter(
			(x) => x.segmentGroup == segmentGroup && x.segment == segment
		);

		if (texts.length == editor.fields.length) {
			setStatus(setPlan, segmentGroup, segment, Status.Done);
		}
	}, [plan, setPlan, segmentGroup, segment, editor]);

	return (
		<div className={styles.ProcessGoalDescription}>
			<div className={styles.Container}>
				<h2 className="h1-style">{header ?? title}</h2>
				{editor.type === 'textBox' &&
					editor.fields
						.map((textBox) => ({
							...textBox,
							planTextsIndex: plan.texts.findIndex(
								(x) =>
									x.segmentGroup === segmentGroup &&
									x.segment === segment &&
									x.key === textBox.key
							),
							defaultText: {
								planId: plan.planId,
								segmentGroup,
								segment,
								key: textBox.key,
								content: '',
							},
						}))
						.map((textBox, i) => (
							<TextUnit
								id={i}
								title={textBox.title}
								description={textBox.description}
								text={
									plan.texts[textBox.planTextsIndex] ?? textBox.defaultText
								}
								setText={
									textBox.planTextsIndex >= 0
										? slice(
												slice<Plan, 'texts'>(setPlan, 'texts'),
												textBox.planTextsIndex
											)
										: (textAction) =>
												slice(
													setPlan,
													'texts'
												)((texts) =>
													texts.concat([
														typeof textAction === 'function'
															? textAction(
																	plan.texts[textBox.planTextsIndex] ??
																		textBox.defaultText
																)
															: textAction,
													])
												)
								}
								key={i}
							/>
						))}
			</div>
		</div>
	);
};

const TextUnit: FC<{
	id: number;
	title: string;
	description?: string;
	text: Text;
	setText: Dispatch<SetStateAction<Text>>;
}> = ({ id, title, description, text, setText }) => {
	const authenticatedFetch = useAuthenticatedFetch();
	const sync = useDebouncedCallback((content: string) => {
		authenticatedFetch(`${API_URL}/plan/${text.planId}/text`, {
			method: 'PUT',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify({
				...text,
				content,
			}),
		}).then(() => setText({ ...text, content }));
	}, 1000);
	return (
		<div>
			<div className={styles.SubGoalDescriptionTitleContainer}>
				<h2>{title}</h2>

				<div className={styles.SubGoalDescriptionButtons}>
					{description && (
						<MoreInfoButton id={id} tooltip={true}>
							<p>{description}</p>
						</MoreInfoButton>
					)}
				</div>
			</div>
			<RichTextEditor
				id={`editor-id${id}`}
				content={text.content}
				setContent={(stringAction) => {
					slice(setText, 'content')(stringAction);
					sync(
						typeof stringAction === 'function'
							? stringAction(text.content)
							: stringAction
					);
				}}
			/>
		</div>
	);
};

export const MoreInfoButton: FC<{
	id?: number;
	tooltip?: boolean;
	onlyIcon?: boolean;
	children: React.ReactNode;
	inline?: boolean;
}> = ({ id, tooltip, children, onlyIcon, inline }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<ToolTip
				id={id ?? Math.floor(Math.random() * 10000)}
				content={tooltip ? children : <p>Klik voor meer informatie</p>}
				clickable={!tooltip}
				inline={inline}
				onlyIcon={onlyIcon}
			>
				{onlyIcon ? (
					<span onClick={() => setOpen(true)}>
						<AppIcon
							icon={
								<Icon
									path={mdiInformationOutline}
									size={1}
									className={styles.InfoIcon}
								/>
							}
						></AppIcon>
					</span>
				) : (
					<AppButton
						onClick={() => setOpen(true)}
						size={Size.Small}
						noMargin
						white
						flat
					>
						<AppIcon
							icon={
								<Icon
									path={mdiInformationOutline}
									size={1}
									className={styles.InfoIcon}
								/>
							}
						>
							Meer informatie?
						</AppIcon>{' '}
					</AppButton>
				)}
			</ToolTip>
			{tooltip ? (
				<></>
			) : (
				<SidePanel title={'Informatie'} open={open} setOpen={setOpen}>
					{children}
				</SidePanel>
			)}
		</>
	);
};

export default TextBox;
