import styles from './NotFound.module.css';
import SideBar from '../../components/SideBar/SideBar';

const NotFound = (): JSX.Element => (
	<>
		<SideBar />
		<div className={styles.NotFound}>
			<h1>404 Error</h1>
			<h2>Deze pagina kan niet gevonden worden</h2>
		</div>
	</>
);

export default NotFound;
