import styles from './Layout.module.css';
import Brand from '../../components/Brand/Brand';
import NavBar from '../../components/NavBar/NavBar';

import { Outlet } from 'react-router-dom';

const Layout = (): JSX.Element => (
	<div className={styles.Layout}>
		<Brand />
		<NavBar />
		<Outlet />
	</div>
);

export default Layout;
