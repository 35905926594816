import styles from './Brand.module.css';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../icons/nijmegen.svg';

const Brand = (): JSX.Element => (
	<div className={styles.Brand}>
		<Link to={'/dashboard'}>
			<Logo title={'je moeder'} className={styles.BrandLogo} />
		</Link>
		<span>|</span>
		<span>Participatietool</span>
	</div>
);

export default Brand;
