import {
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';
import styles from './PlanOverview.module.css';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Plan, Status } from '../../types';
import { PlanContext } from '../../contexts/PlanContext';
import StatusIcon from '../../components/StatusIcon/StatusIcon';
import SideBar from '../../components/SideBar/SideBar';
import Loader from '../../components/Loader/Loader';
import { segmentGroups, segments } from '../../segments';
import { useAuthenticatedFetch } from '../../auth';
import SegmentView from '../Segments/SegmentView';
import styled from 'styled-components';

const API_URL = process.env.REACT_APP_BACKEND;

export const StyledImagePreview = styled.img`
	max-width: 200px;

	&:hover {
		cursor: pointer;
		filter: brightness(0.85);
	}
`;

const PlanOverview = (): JSX.Element => {
	const { planId } = useParams<{ planId?: string }>();
	const [plan, setPlan] = useState<Plan | undefined>();
	const [loading, setLoading] = useState(true);
	const authenticatedFetch = useAuthenticatedFetch();

	const setPlanFromBackend = useCallback((plan: Plan) => {
		setPlan({
			...plan,
			steps: plan.steps
				.sort((a, b) => a.order - b.order)
				.map((step) => ({
					...step,
					subSteps: step.subSteps.sort((a, b) => a.order - b.order),
				})),
		});
	}, []);

	useEffect(() => {
		if (!planId) {
			return;
		}
		authenticatedFetch(`${API_URL}/plan/${planId}`)
			.then((response) => response.json())
			.then((plan: Plan) => setPlanFromBackend(plan))
			.catch((e) => console.log(e))
			.finally(() => setLoading(false));
	}, [planId, setPlanFromBackend, authenticatedFetch]);

	return (
		<PlanContext.Provider
			value={{
				plan: plan,
				setPlan: setPlan as Dispatch<SetStateAction<Plan>>,
				setPlanFromBackend: setPlanFromBackend,
			}}
		>
			{!loading ? (
				<Routes>
					<Route
						index
						element={plan ? <PlanOverviewContent plan={plan} /> : <SideBar />}
					/>
					<Route
						path="segment/:segmentGroup/:segment/*"
						element={plan ? <SegmentView /> : null}
					/>
				</Routes>
			) : (
				<>
					<SideBar />
					<div className={styles.PageLoading}>
						<Loader />
					</div>
				</>
			)}
		</PlanContext.Provider>
	);
};

const PlanOverviewContent: FC<{ plan: Plan }> = ({ plan: plan }) => (
	<>
		<SideBar />
		<div className={styles.PlanOverview}>
			<h2 className="h1-style">Dashboard</h2>
			<section>
				<p>
					In Nijmegen werken we projectmatig en opgavegericht. Zo leveren we
					gestructureerd maatwerk. Voor participatie zijn, in de verschillende
					projectfasen, een aantal vaste stappen gedefinieerd. Zo werken we
					eenduidig volgens een vast stramien en zetten we de juiste stappen
					voor goede participatie. In het doorlopen van de initiatief fase van
					deze tool werk je aan het participatieplan. Na afronding van deze
					fase kun je het plan middels de knop in het hoofdmenu downloaden.
				</p>
				<a href="/images/info/Stappenplan.png" target="_blank">
					<StyledImagePreview
						alt="Overzicht van het stappenplan"
						src="/images/info/Stappenplan.png"
					/>
				</a>
			</section>
			<DashboardItems plan={plan} />
		</div>
	</>
);

const DashboardItems: FC<{ plan: Plan }> = ({ plan }) => {
	const navigate = useNavigate();

	return (
		<>
			<section>
				<div className={styles.PlanOverviewSegments}>
					{segmentGroups.map(({ segmentGroup, title }, i) => (
						<div key={segmentGroup} className={styles.PhaseContainer}>
							<h2>
								{i + 1}. {title}
							</h2>
							<div className={styles.PhaseSegments}>
								<table role={'presentation'} className={styles.Table}>
									<thead>
										<tr>
											<th>Onderdeel</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>
										{segments
											.filter((x) => x.segmentGroup === segmentGroup)
											.map((segment, i) => {
												const onRowClick = () => {
													navigate(
														`/plan/${plan.planId}/segment/${segment.segmentGroup}/${segment.segment}`
													);
												};

												return (
													<tr onClick={onRowClick} key={i}>
														<td>
															<span>{segment.title}</span>
														</td>
														<td className={styles.TableIconCell}>
															{
																<StatusIcon
																	status={
																		plan.segmentStatuses.find(
																			(x) =>
																				x.segmentGroup === segmentGroup &&
																				x.segment === segment.segment
																		)?.status ?? Status.NoStatus
																	}
																	showStatusAsText={true}
																/>
															}
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						</div>
					))}
				</div>
			</section>
		</>
	);
};

export default PlanOverview;
