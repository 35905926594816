import { FC, KeyboardEventHandler, useState } from 'react';
import { Plan } from '../../types';
import styles from './Typeahead.module.css';
import CreatableSelect from 'react-select/creatable';
import { useDebouncedCallback } from 'use-debounce';

interface Option {
	readonly value: string;
	readonly label: string;
}

const components = {
	DropdownIndicator: null,
};

const customStyles = {
	control: (
		provided: Record<string, unknown>,
		state: { isFocused: boolean }
	) => ({
		...provided,
		cursor: 'text',
		boxShadow: state.isFocused ? '0 0 0 1px #157C68' : 'none',
		borderColor: state.isFocused ? '#157C68' : '#CED4DA',
	}),
};

const Typeahead: FC<{
	placeholder: string;
	options: Plan[];
	setOptions: (options: Plan[]) => void;
	inputId?: string; // Might be provided in case a label with an htmlFor attribute is used in front of this component.
}> = ({ placeholder, options, setOptions, inputId }) => {
	const [inputValue, setInputValue] = useState('');
	const [categories, setCategories] = useState<Option[]>([]);

	const debouncedSetOptions = useDebouncedCallback(
		(searchValues: string[]) =>
			setOptions(
				searchValues.length < 1
					? options
					: options.filter((option) => {
							for (const value of searchValues) {
								if (
									option.title.toLowerCase().indexOf(value.toLowerCase()) == -1
								) {
									return false;
								}
							}
							return true;
						})
			),
		500
	);
	const filterOptions = (categories: Option[], newValue: string) => {
		const searchValues = [
			...categories.map((category) => category.label),
			newValue,
		].filter((x) => x);
		debouncedSetOptions(searchValues);
	};

	const handleFilter = () => {
		if (inputValue) {
			setInputValue('');
			setCategories([
				...categories,
				{
					label: inputValue,
					value: `${categories.length}`,
				},
			]);
		}
	};

	const handleOnKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
		switch (event.key) {
			case 'Enter':
			case 'Tab':
				handleFilter();
				event.preventDefault();
				break;
			case 'Backspace':
				if (!inputValue) {
					setCategories(categories.slice(0, -1));
				}
				break;
		}
	};

	const handleOnInputChange = (
		inputValue: string,
		action: { action: string }
	) => {
		// This prevents reset of the input on blur
		if (action.action !== 'input-blur' && action.action !== 'menu-close') {
			setInputValue(inputValue);
			filterOptions(categories, inputValue);
		}
	};

	return (
		<div className={styles.Container}>
			<CreatableSelect
				components={components}
				isClearable={false}
				// I set isClearable to false, since the clear button overlapped with the search icon
				// and the clearing function didn't work anyway
				isSearchable
				isMulti
				menuIsOpen={false}
				placeholder={placeholder}
				value={categories}
				inputValue={inputValue}
				options={options.map((option) => ({
					value: option.title,
					label: option.title,
				}))}
				onKeyDown={handleOnKeyDown}
				onInputChange={handleOnInputChange}
				styles={customStyles}
				className={styles.CreatableSelectContainer}
				inputId={inputId}
			/>
			<SearchButton onClick={handleFilter} />
		</div>
	);
};

const SearchButton: FC<{
	onClick: () => void;
}> = ({ onClick }) => (
	<div onClick={onClick} className={styles.SearchButton}></div>
);

export default Typeahead;
