import { FC, useContext, useEffect, useState } from 'react';
import styles from './Dashboard.module.css';
import { Plan, Size } from '../../types';
import { useNavigate } from 'react-router-dom';
import SideBar from '../../components/SideBar/SideBar';
import SidePanel from '../../components/SidePanel/SidePanel';
import DateFormatter from '../../components/DateFormatter/DateFormatter';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import AppIcon from '../../components/AppIcon/AppIcon';
import AppButton from '../../components/Button/AppButton';
import Typeahead from '../../components/Typeahead/Typeahead';
import AppInput from '../../components/AppInput/AppInput';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import Icon from '@mdi/react';
import {
	mdiBookmarkPlus,
	mdiCog,
	mdiContentCopy,
	mdiDelete,
	mdiPencil,
	mdiPlusBox,
	mdiAccountOutline,
} from '@mdi/js';
import { calculateProgress, planStatus } from '../../components/Status/Status';
import { parseJWT, TokenContext, useAuthenticatedFetch } from '../../auth';
import Accordion from '../../components/Accordion/Accordion';
import styled from 'styled-components';
import DownloadLink from '../../components/DownloadLink/DownloadLink';

const API_URL = process.env.REACT_APP_BACKEND;

const StyledAccordion = styled(Accordion)`
	margin-bottom: 2rem;
`;

const Dashboard = (): JSX.Element => {
	const [plans, setPlans] = useState<Plan[]>([]);
	const [filteredPlans, setFilteredPlans] = useState<Plan[]>([]);
	const [paginatedPlans, setPaginatedPlans] = useState<Plan[]>([]);

	const [examplePlans, setExamplePlans] = useState<Plan[]>([]);
	const [filteredExamplePlans, setFilteredExamplePlans] = useState<Plan[]>([]);
	const [paginatedExamplePlans, setPaginatedExamplePlans] = useState<Plan[]>(
		[]
	);
	const [loading, setLoading] = useState(true);
	const { token } = useContext(TokenContext);
	const authenticatedFetch = useAuthenticatedFetch();

	useEffect(() => {
		authenticatedFetch(`${process.env.REACT_APP_BACKEND}/plan`, {
			headers: {
				'Content-type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((plans: Plan[]) => {
				const { sub: userId } = parseJWT(token!);
				setPlans(plans.filter((x) => x.user.userId == userId));
				setFilteredPlans(plans.filter((x) => x.user.userId == userId));
				setExamplePlans(plans.filter((x) => x.user.userId !== userId));
				setFilteredExamplePlans(plans.filter((x) => x.user.userId !== userId));
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [token, authenticatedFetch]);

	function sortByCreationDateDesc(items: Plan[]): Plan[] {
		// without the spread operator sort will mutate the original array
		return items.length > 1
			? [...items].sort(
					(a, b) =>
						new Date(b.creationDate).getTime() -
						new Date(a.creationDate).getTime()
				)
			: items;
	}

	const [open, setOpen] = useState(true);
	const [openAccordion, setOpenAccordion] = useState<number>(0);

	return (
		<>
			<SideBar>
				<h1>Samenwerken met de Stad</h1>
				<p>
					Welkom bij de participatietool van gemeente Nijmegen! In onze
					samenwerking met de stad helpt deze tool je om in de verschillende
					fasen van een project, plan of initiatief het juiste te doen.
				</p>
				<p>
					Bovendien dient de tool ter ondersteuning in de uitvoering van het
					participatiebeleid voor ruimtelijke initiatieven.
				</p>
				<p>
					Op deze startpagina vind je de algemene informatie over participeren
					in Nijmegen. Ook kun je er je eigen projecten en participatieplannen
					beheren en participatieplannen van collega&apos;s inzien.
				</p>
				<p>
					Deze eerste versie is primair gericht op participatieplannen. Heb je
					vragen, opmerkingen of tips over de tool? Mail ze naar
					r.tannemaat@nijmegen.nl
				</p>
			</SideBar>
			<div className={styles.Dashboard}>
				<StyledAccordion
					title={'Algemene informatie'}
					id={0}
					isOpen={open && openAccordion == 0}
					setOpen={setOpen}
					setActiveItem={setOpenAccordion}
				>
					<p>
						Vertrekpunt voor het opstellen van deze participatie-tool is de
						wens is om een moderne overheid te zijn en navenant een goede
						invulling te geven aan participatie, onze samenwerking met de stad.
						In de opdracht hiervoor is gevraagd om een stappenplan,
						afwegingskader en gezamenlijke taal om beter te kunnen
						participeren.
						<ul>
							<li>
								<DownloadLink
									target="_blank"
									download={'Participatiebeleid_ruimtelijk_domein.docx'}
									rel="noreferrer"
									to={
										process.env.PUBLIC_URL +
										'/downloads/Participatiebeleid_ruimtelijk_domein.docx'
									}
								>
									Participatiebeleid voor ruimtelijke initiatieven
								</DownloadLink>
							</li>
							<li>
								<DownloadLink
									target="_blank"
									download={'Stappenplan.pdf'}
									rel="noreferrer"
									to={process.env.PUBLIC_URL + '/downloads/Stappenplan.pdf'}
								>
									Stappenplan
								</DownloadLink>
							</li>
							<li>
								<DownloadLink
									target="_blank"
									download={'Afwegingskader.pdf'}
									rel="noreferrer"
									to={process.env.PUBLIC_URL + '/downloads/Afwegingskader.pdf'}
								>
									Afwegingskader
								</DownloadLink>
							</li>
							<li>
								<DownloadLink
									target="_blank"
									download={'Kernthemas_van_participatie.pdf'}
									rel="noreferrer"
									to={
										process.env.PUBLIC_URL +
										'/downloads/Kernthemas_van_participatie.pdf'
									}
								>
									Kernthema&apos;s van participatie
								</DownloadLink>
							</li>
							<li>
								<DownloadLink
									target="_blank"
									download={'Grondhouding.pdf'}
									rel="noreferrer"
									to={process.env.PUBLIC_URL + '/downloads/Grondhouding.pdf'}
								>
									Grondhouding
								</DownloadLink>
							</li>
							<li>
								<DownloadLink
									target="_blank"
									download={'Basisafspraken.pdf'}
									rel="noreferrer"
									to={process.env.PUBLIC_URL + '/downloads/Basisafspraken.pdf'}
								>
									Basisafspraken
								</DownloadLink>
							</li>
						</ul>
					</p>
				</StyledAccordion>
				<section>
					{plans.length ? (
						<>
							<label htmlFor="search-my-projects">Zoek hieronder</label>
							<Typeahead
								placeholder={'Zoek in eigen projecten'}
								options={plans}
								setOptions={setFilteredPlans}
								inputId="search-my-projects"
							/>
							<PlansTable plans={paginatedPlans} loading={loading} />
						</>
					) : (
						<p>Geen projecten</p>
					)}
					<Pagination
						items={sortByCreationDateDesc(filteredPlans)}
						setItems={setPaginatedPlans}
					/>
					<AddSidePanelButton />
				</section>
				<section>
					<h2>Voorbeeld participatieplannen</h2>
					{examplePlans.length ? (
						<>
							<label htmlFor="search-example-projects">Zoek hieronder</label>
							<Typeahead
								placeholder={"Zoek in plannen collega's"}
								options={examplePlans}
								setOptions={setFilteredExamplePlans}
								inputId="search-example-projects"
							/>
							<ColleaguePlansTable
								plans={paginatedExamplePlans}
								loading={loading}
							/>
						</>
					) : (
						<p>Geen projecten</p>
					)}
					<Pagination
						items={sortByCreationDateDesc(filteredExamplePlans)}
						setItems={setPaginatedExamplePlans}
					/>
				</section>
			</div>
		</>
	);
};

const PlansTable: FC<{ plans: Plan[]; loading: boolean }> = ({
	plans,
	loading,
}) => {
	const navigate = useNavigate();

	return (
		<table role="presentation">
			<thead>
				<tr>
					<th scope="col" className={styles.Small}>
						Datum
					</th>
					<th scope="col" className={styles.Medium}>
						Naam Project
					</th>
					<th scope="col" className={styles.Small}>
						voltooid
					</th>
					<th scope="colgroup" colSpan={2} className={styles.Medium}>
						Status participatieplan
					</th>
				</tr>
			</thead>
			<tbody>
				{!loading ? (
					<>
						{plans.map((plan, i) => (
							<tr
								onClick={(e) =>
									!(e.target as HTMLElement).closest('.block-row-click') &&
									navigate(`/plan/${plan.planId}`)
								}
								key={i}
							>
								<td>
									<DateFormatter
										date={plan.creationDate}
										format="dd-MM-yyyy"
									/>
								</td>
								<td>
									<span>{plan.title}</span>
								</td>
								<td>
									<ProgressBar
										progress={
											calculateProgress(plan) > 0.015
												? calculateProgress(plan)
												: 0
										}
									/>
								</td>
								<td>{planStatus(plan.segmentStatuses)}</td>
								<td
									className={`${styles.TableButtonsContainer} block-row-click`}
								>
									<EditSidePanelButton plan={plan} />
									<DeleteSidePanelButton plan={plan} />
									<CopySidePanelButton plan={plan} />
								</td>
							</tr>
						))}
					</>
				) : (
					<tr>
						<td colSpan={4}>
							<Loader />
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};

const ColleaguePlansTable: FC<{ plans: Plan[]; loading: boolean }> = ({
	plans,
	loading,
}) => {
	const navigate = useNavigate();

	return (
		<table>
			<thead>
				<tr>
					<th scope="col" className={styles.Small}>
						Datum
					</th>
					<th scope="col" className={styles.Medium}>
						Naam Project
					</th>
					<th scope="col" className={styles.Small}>
						Opsteller
					</th>
					<th scope="col" className={styles.Medium}>
						Fase
					</th>
					<th scope="col" className={styles.TableButtonsHead} />
				</tr>
			</thead>
			<tbody>
				{!loading ? (
					<>
						{plans.map((plan, i) => (
							<tr
								onClick={(e) =>
									!(e.target as HTMLElement).closest('.block-row-click') &&
									navigate(`/plan/${plan.planId}`)
								}
								key={i}
							>
								{' '}
								<td>
									<DateFormatter
										date={plan.creationDate}
										format="dd-MM-yyyy"
									/>
								</td>
								<td>
									<span>{plan.title}</span>
								</td>
								<td>
									<AppIcon
										icon={
											<Icon
												path={mdiAccountOutline}
												size={1}
												className={styles.AccountOutlined}
											/>
										}
										size={Size.ExtraSmall}
									>
										{plan.user?.name}
									</AppIcon>
								</td>
								<td>{planStatus(plan.segmentStatuses)}</td>
								<td
									className={`${styles.TableButtonsContainer} block-row-click`}
								>
									<CopySidePanelButton plan={plan} />
								</td>
							</tr>
						))}
					</>
				) : (
					<tr>
						<td colSpan={4}>
							<Loader />
						</td>
					</tr>
				)}
			</tbody>
		</table>
	);
};

const AddSidePanelButton = (): JSX.Element => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<AddSidePanel open={open} setOpen={setOpen} />
			<AppButton onClick={() => setOpen(true)}>
				<AppIcon
					icon={<Icon path={mdiBookmarkPlus} size={1} />}
					size={Size.Large}
					iconRight
				>
					NIEUW INITIATIEF AANMAKEN
				</AppIcon>
			</AppButton>
		</>
	);
};

const AddSidePanel: FC<{
	open: boolean;
	setOpen: (open: boolean) => void;
}> = ({ open, setOpen }) => {
	const [title, setTitle] = useState('');
	const navigate = useNavigate();

	const authenticatedFetch = useAuthenticatedFetch();
	const { token } = useContext(TokenContext);

	const addPlan = () => {
		const { sub: userId, name } = parseJWT(token!);
		authenticatedFetch(`${API_URL}/plan`, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify({
				title: title,
				user: { userId, name },
			}),
		})
			.then((r) => r.json())
			.then((plan: Plan) => {
				if (plan.planId) {
					navigate(`/plan/${plan.planId}`);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<SidePanel
			title={'Nieuw initiatief Aanmaken'}
			open={open}
			setOpen={setOpen}
		>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					addPlan();
				}}
			>
				<AppInput
					label={'Naam van het initiatief'}
					onChange={(event) => setTitle(event.target.value)}
					required
					autoFocus
				/>
				<AppButton type={'submit'}>
					<AppIcon icon={<Icon path={mdiPlusBox} size={1} />} iconRight>
						Toevoegen
					</AppIcon>
				</AppButton>
			</form>
		</SidePanel>
	);
};

const EditSidePanelButton: FC<{ plan: Plan }> = ({ plan }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<EditSidePanel plan={plan} open={open} setOpen={setOpen} />
			<div onClick={() => setOpen(true)}>
				<Icon path={mdiCog} size={1} className={styles.TableActionButton} />
			</div>
		</>
	);
};

const EditSidePanel: FC<{
	plan: Plan;
	open: boolean;
	setOpen: (open: boolean) => void;
}> = ({ plan, open, setOpen }) => {
	const [title, setTitle] = useState(plan.title);
	useEffect(() => {
		setTitle(plan.title);
	}, [setTitle, plan]);

	const navigate = useNavigate();
	const authenticatedFetch = useAuthenticatedFetch();
	const editPlan = () => {
		plan.title = title;

		return authenticatedFetch(`${API_URL}/plan`, {
			method: 'PUT',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify(plan),
		}).then((r) => {
			console.log(r);
		});
	};

	return (
		<SidePanel title={'Initiatief aanpassen'} open={open} setOpen={setOpen}>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					editPlan().then(() => navigate(0));
				}}
			>
				<AppInput
					label={'Nieuwe naam van het initiatief'}
					placeholder={plan.title}
					value={title}
					onChange={(event) => setTitle(event.target.value)}
					required
					autoFocus
				/>
				<AppButton type={'submit'}>
					<AppIcon icon={<Icon path={mdiPencil} size={1} />} iconRight>
						Aanpassen
					</AppIcon>
				</AppButton>
			</form>
		</SidePanel>
	);
};

const DeleteSidePanelButton: FC<{ plan: Plan }> = ({ plan }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<DeleteSidePanel plan={plan} open={open} setOpen={setOpen} />
			<div className={styles.test} onClick={() => setOpen(true)}>
				<Icon path={mdiDelete} size={1} className={styles.TableActionButton} />
			</div>
		</>
	);
};

const DeleteSidePanel: FC<{
	plan: Plan;
	open: boolean;
	setOpen: (open: boolean) => void;
}> = ({ plan, open, setOpen }) => {
	const navigate = useNavigate();
	const authenticatedFetch = useAuthenticatedFetch();

	const deletePlan = () =>
		authenticatedFetch(`${API_URL}/plan/${plan.planId}`, {
			method: 'DELETE',
		});

	return (
		<SidePanel title={'Project verwijderen'} open={open} setOpen={setOpen}>
			<div>
				Weet u zeker dat u &ldquo;{plan.title}&rdquo; wilt verwijderen?
			</div>
			<AppButton onClick={() => deletePlan().then(() => navigate(0))}>
				Verwijderen
			</AppButton>
		</SidePanel>
	);
};

const CopySidePanelButton: FC<{ plan: Plan }> = ({ plan }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<CopySidePanel plan={plan} open={open} setOpen={setOpen} />
			<div onClick={() => setOpen(true)}>
				<Icon
					path={mdiContentCopy}
					size={1}
					className={styles.TableActionButton}
				/>
			</div>
		</>
	);
};

const CopySidePanel: FC<{
	plan: Plan;
	open: boolean;
	setOpen: (open: boolean) => void;
}> = ({ plan, open, setOpen }) => {
	const [title, setTitle] = useState(`${plan.title} - Kopie`);
	const navigate = useNavigate();
	const authenticatedFetch = useAuthenticatedFetch();
	const { token } = useContext(TokenContext);
	useEffect(() => {
		setTitle(`${plan.title} - Kopie`);
	}, [setTitle, plan]);

	const copyPlan = () => {
		const { sub: userId, name } = parseJWT(token!);
		authenticatedFetch(`${API_URL}/plan/${plan.planId}`, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify({
				title: title,
				user: { userId, name },
			}),
		})
			.then((r) => r.json())
			.then((copiedPlan: Plan) => {
				console.log(copiedPlan);
				if (plan.planId) {
					navigate(`/plan/${copiedPlan.planId}`);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<SidePanel title={'Project kopiëren'} open={open} setOpen={setOpen}>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					copyPlan();
				}}
			>
				<AppInput
					label={'Naam van het project'}
					placeholder={plan.title}
					value={title}
					onChange={(event) => setTitle(event.target.value)}
					required
					autoFocus
				/>
				<AppButton type={'submit'}>
					<AppIcon icon={<Icon path={mdiPlusBox} size={1} />} iconRight>
						Toevoegen
					</AppIcon>
				</AppButton>
			</form>
		</SidePanel>
	);
};

export default Dashboard;
