import { FC, useEffect, useState } from 'react';
import { Plan, Segment, SegmentGroup } from '../../types';
import StepLink from '../NavBar/StepLink';
import styles from './Roadmap.module.css';
import { segmentGroups, segments } from '../../segments';
import AppIcon from '../AppIcon/AppIcon';
import { mdiChevronRight, mdiSitemap } from '@mdi/js';
import Icon from '@mdi/react';
import Accordion from '../Accordion/Accordion';
import AppButton from '../Button/AppButton';

const Roadmap: FC<{
	plan: Plan;
	activeSegmentGroup?: SegmentGroup;
	activeSegment?: Segment;
}> = ({ plan, activeSegmentGroup, activeSegment }) => {
	const [open, setOpen] = useState(false);
	const [openAccordion, setOpenAccordion] = useState<number>(0);
	useEffect(() => {
		setOpenAccordion(openAccordion);
	}, [openAccordion]);

	return (
		plan && (
			<>
				<AppButton
					noMargin={true}
					flat={true}
					noBackground={true}
					key={0}
					onClick={() => setOpen(!open)}
				>
					<AppIcon icon={<Icon path={mdiSitemap} size={0.8} />}>
						{!open ? 'Open' : 'Sluit'} stappenplan
					</AppIcon>
				</AppButton>

				<Accordion
					title=""
					id={0}
					isOpen={open && openAccordion == 0}
					setOpen={setOpen}
					setActiveItem={setOpenAccordion}
					showHeader={false}
					showStyling={false}
					className={styles.RoadmapAccordion}
				>
					<section>
						<div className={styles.ProcessOverviewRoadmap}>
							{segmentGroups.map((segmentGroup, i) => (
								<RoadmapPart
									key={i}
									{...segmentGroup}
									planId={plan.planId}
									activeSegment={activeSegment}
									activeSegmentGroup={activeSegmentGroup}
								/>
							))}
						</div>
					</section>
				</Accordion>
			</>
		)
	);
};

const RoadmapPart: FC<{
	segmentGroup: SegmentGroup;
	title: string;
	planId: number;
	activeSegmentGroup?: SegmentGroup;
	activeSegment?: Segment;
}> = ({ segmentGroup, title, planId, activeSegmentGroup, activeSegment }) => (
	<div className={styles.Roadmap}>
		<div className={styles.Header}>
			<h2>{title}</h2>
			<span>
				<Icon path={mdiChevronRight} size={0.6} />
			</span>
		</div>
		<ul>
			{segments
				.filter((x) => x.segmentGroup === segmentGroup)
				.map((segment, i) => (
					<li
						key={i}
						className={
							segment.segmentGroup == activeSegmentGroup &&
							segment.segment == activeSegment
								? styles.Active
								: ''
						}
					>
						<StepLink planId={planId} {...segment} />
					</li>
				))}
		</ul>
	</div>
);

export default Roadmap;
