import { Dispatch, FC, SetStateAction, useState } from 'react';
import styles from './RichTextEditor.module.css';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
Quill.register(Quill.import('attributors/style/align'), true);

const toolbarFormats = [
	[
		{
			type: 'bold',
			ariaLabel: 'Vetgedrukt maken (ctrl + b)',
		},
		{
			type: 'italic',
			ariaLabel: 'Schuingedrukt maken (ctrl + i)',
		},
		{
			type: 'underline',
			ariaLabel: 'Onderstrepen (ctrl + u)',
		},
		{
			type: 'strike',
			ariaLabel: 'Doorstrepen',
		},
	],
	[
		{
			type: 'clean',
			ariaLabel: 'Opmaak verwijderen',
		},
	],
];

const CustomToolbar: FC<{ id: string }> = ({ id }) => {
	return (
		<>
			<div id={id}>
				{toolbarFormats.map((items, index) => (
					<div key={index} className="ql-formats">
						{items.map((btn, key) => (
							<button
								key={key}
								className={`ql-${btn.type}`}
								type="button"
								aria-label={btn.ariaLabel}
							></button>
						))}
					</div>
				))}
			</div>
		</>
	);
};

const RichTextEditor: FC<{
	content: string;
	setContent: Dispatch<SetStateAction<string>>;
	id: string;
}> = ({ content, setContent, id: toolbarId }) => {
	const [focus, setFocus] = useState(false);

	const modules = {
		toolbar: {
			container: `#${toolbarId}`,
		},
		keyboard: {
			bindings: {
				tab: false,
			},
		},
	};

	const formats = ['bold', 'italic', 'underline', 'strike'];

	return (
		<div>
			<CustomToolbar id={toolbarId} />
			<ReactQuill
				className={`${styles.RichTextEditor} ${focus ? styles.Focus : ''}`}
				theme="snow"
				modules={modules}
				formats={formats}
				value={content}
				onChange={setContent}
				placeholder="Voer hier tekst in..."
				preserveWhitespace={true}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
			/>
		</div>
	);
};

export default RichTextEditor;
