import React, { Dispatch, SetStateAction } from 'react';
import DefineParticipationPlan from './pages/Segments/ParticipationProcess/ParticipationProcess';
import OrganiseStakeholders from './pages/Segments/OrganiseStakeholders/OrganiseStakeholders';
import Stakeholders from './pages/Segments/Stakeholders/Stakeholders';
import { Plan, Segment, SegmentGroup } from './types';
import { hyperlink } from './pages/Segments/SegmentView';

export const segmentGroups = [
	{ segmentGroup: SegmentGroup.Initiative, title: 'Initiatief fase' },
	{ segmentGroup: SegmentGroup.Definition, title: 'Definitie fase' },
	{ segmentGroup: SegmentGroup.Design, title: 'Ontwerp fase' },
	{ segmentGroup: SegmentGroup.Preparation, title: 'Voorbereidings fase' },
	{ segmentGroup: SegmentGroup.Implementation, title: 'Uitvoerings fase' },
	{ segmentGroup: SegmentGroup.Aftercare, title: 'Nazorg fase' },
];

export type SegmentTemplate = {
	segmentGroup: SegmentGroup;
	segment: Segment;
	title: string;
	header?: string;
	info?: {
		image?: string;
		imageTitle?: string;
		paragraphs?: string[];
		hyperlinks?: hyperlink[];
	};
} & (
	| { type: 'none' }
	| {
			type: 'textBox';
			fields: { title: string; key: string; description?: string }[];
	  }
	| {
			type: 'component';
			component: React.FC<{
				plan: Plan;
				setPlan: Dispatch<SetStateAction<Plan>>;
				template: SegmentTemplate;
			}>;
	  }
	| {
			type: 'text';
			content: string;
			buttons?: {
				title: string;
				fileName: string;
				filePath: string;
				key: string;
			}[];
	  }
);

export const segments: SegmentTemplate[] = [
	/* Initiative Phase*/
	{
		segmentGroup: SegmentGroup.Initiative,
		segment: Segment.Goal,
		title: 'Doel en doelstelling(en)',
		header: 'Doel en doelstelling(en) van initiatief en participatie',
		type: 'textBox',
		fields: [
			{
				key: 'initiativePurpose',
				title: 'Doel van het initiatief',
				description:
					'Het doel van het initiatief kan meerledig zijn. Bijvoorbeeld: technisch, (sociaal-)maatschappelijk, economisch, ruimtelijk of organisatorisch.',
			},
			{
				key: 'initiativeTargets',
				title: 'Doelstellingen van het initiatief',
				description:
					'Doelstellingen van het initiatief kunnen gaan over (bijvoorbeeld) het tijdspad, budget of kwaliteitseis.',
			},
			{
				key: 'participationPurpose',
				title: 'Doel van de participatie',
				description:
					'Het doel van de participatie gaat over het gewenste resultaat van de participatie-inspanningen. Let daarbij op de haalbaarheid van wat je wenst: breed draagvlak voor een plan is bijvoorbeeld vaak zeer gewenst, echter met publieke opgaven niet altijd haalbaar.',
			},
			{
				key: 'participationTargets',
				title: 'Doelstellingen van de participatie',
				description:
					'Participatiedoelstellingen kunnen bijvoorbeeld gaan over het betrekken van specifieke groepen of het bereiken van een hoge participatiegraad op een specifiek onderdeel van het proces. Bijvoord: draagvlak verkrijgen onder direct belanghebbenden voor ... [specifiek onderdeel].',
			},
		],
		info: {
			paragraphs: [
				'Voor een goed proces is het essentieel duidelijkheid te geven over de participatiekaders. Schrijf daarom zo concreet mogelijk het doel en de doelstellingen van zowel het initiatief als het participatieproces op.',
				'Doel is wat je uiteindelijk wilt bereiken. Doelstelling is een specifieker voornemen of resultaat om het gewenste doel te kunnen bereiken.',
				"Hieronder staan de BIG 5 van participatie afgebeeld: 5 kernthema's waarvan het belangrijk is hier tijdig helderheid in te verkrijgen.",
			],
			imageTitle: 'Kernvragen BIG 5',
			image: '/images/info/Kernvragen.png',
		},
	},
	{
		segmentGroup: SegmentGroup.Initiative,
		segment: Segment.ExploratoryPreliminaryPhase,
		title: 'Verkennende voorfase',
		type: 'text',
		// buttons: [
		//   {
		//     key: 'initiativePurpose',
		//     title: 'Template verslag verkennende voorfase',
		//     filePath: '/downloads/',
		//     fileName: 'Template_verslag_verkennende_voorfase.pdf',
		//   },
		// ],
		content: `<p>We gaan zo vroeg mogelijk in dialoog met de omgeving, zodat er actief afstemming is over de inhoud en impact van een nieuw initiatief. Dit doen we in deze verkennende voorfase waarin kaders nog niet zijn vastgesteld.</p>
      <p>In deze fase krijgt de omgeving inzicht in</p>
        <ul>
          <li>De (o.a. ruimtelijke) impact en de (maatschappelijke) waarde van het initiatief.</li>
          <li>De belangen die mogelijk een rol spelen bij een besluit over het plan.</li>
          <li>De  mogelijke alternatieven met bijbehorende voor- en nadelen.</li>
          <li>Inzicht in eventuele hinder die het initiatief oplevert.</li>
          <li>Een eerste analyse over hoe het plan zich verhoudt tot de gemeentelijke kaders (past het binnen de Omgevingsvisie of het Omgevingsplan of niet, of is daar een wijziging voor nodig).</li>
          <li>Een omschrijving van het vervolgproces dat de initiatiefnemer voor ogen heeft.</li>
        </ul>      
      <p>Middels een dialoog worden in deze fase tijdig signalen van zorg, weerstand, steun of verrijking opgehaald. Met deze informatie wordt de verdere participatie vormgegeven en uitgevoerd.</p><p>Van deze verkennende fase wordt een verslag opgesteld en gedeeld op <a href="https://nijmegen.mijnwijkplan.nl/">Nijmegen.mijnwijkplan.nl</a>.</p>`,
	},
	{
		segmentGroup: SegmentGroup.Initiative,
		segment: Segment.History,
		title: 'Kaders en achtergrond',
		type: 'textBox',
		fields: [
			{
				key: 'background',
				title: 'Achtergrond van het initiatief',
				description:
					'Omschrijf de relevante achtergrond en voorgeschiedenis van dit initiatief die van invloed is op de participatie-aanpak.',
			},
			{
				key: 'framework',
				title: 'Kaders van het initiatief',
				description:
					'Bij projecten met grote impact voor de stad kan de raad vooraf voorwaarden voor participatie meegeven. Als dit van toepassing is op dit project vermeldt ze dan hier.',
			},
			{
				key: 'requirements',
				title: 'Voorwaarden van de raad bij het initiatief',
				description:
					'Bij projecten met grote impact geeft de raad vooraf voorwaarden voor participatie mee. Bepaal bij "Impact op de omgeving" het participatie-level om te zien of dit nodig is voor dit project. Zo ja, vermeldt dan hier de voorwaarden van de raad.',
			},
		],
		info: {
			paragraphs: [
				'Overal waar een initiatief start is er wel een relevante voorgeschiedenis die van invloed is op de participatie. Er is bijvoorbeeld al eerder een vergelijkbaar project uitgevoerd, er leven nog emoties uit een ander proces of er zijn toezeggingen uit andere initiatieven die invloed uitoefenen.',
				'Inventariseer en benoem daarom de aanvullende kaders uit achtergrond en neem deze mee in de totstandkoming van een passend participatie-aanpak.',
				"Hieronder staan de BIG 5 van participatie afgebeeld: 5 kernthema's waarvan het belangrijk is hier tijdig helderheid in te verkrijgen.",
			],
			imageTitle: 'Kernvragen BIG 5',
			image: '/images/info/Kernvragen.png',
		},
	},
	{
		segmentGroup: SegmentGroup.Initiative,
		segment: Segment.Stakeholders,
		title: 'Belanghebbenden',
		type: 'component',
		component: Stakeholders,
	},
	{
		segmentGroup: SegmentGroup.Initiative,
		segment: Segment.EnvironmentImpact,
		title: 'Impact op de omgeving',
		type: 'textBox',
		fields: [
			{
				key: 'impactscan',
				title: 'Impactscan',
				description:
					'Beschrijf de verwachte impact van het initiatief op de belanghebbenden. Zowel tijdens de voorbereidings- en uitvoeringsfase als na realisatie. Impact kan zowel positief als negatief ervaren worden op diverse gebieden: leefbaarheid, economisch, sociale cohesie, bereikbaarheid, veiligheid etc. Gebruik de informatie uit de verkennende voorfase hierbij',
			},
		],
	},
	{
		segmentGroup: SegmentGroup.Initiative,
		segment: Segment.OrganiseStakeholders,
		title: 'Organisatie van belanghebbenden',
		type: 'component',
		component: OrganiseStakeholders,
		info: {
			paragraphs: [
				'<a href="segment/definition/cooperationFrame">Belanghebbenden rondom een initiatief</a> kunnen individuele personen zijn of georganiseerde groepen. Soms is het nodig om ten behoeve van het project een nieuwe (tijdelijke) groep te formeren met een specifiek domein en/of mandaat. Bijvoorbeeld een klankbordgroep.',
				'Bepaal in deze stap hoe belanghebbenden georganiseerd zijn.  Deze groep, partijen of personen kunnen vervolgens in het participatieproces als betrokkenen gekoppeld worden aan de verschillende stappen in het proces.',
				'In de definitie fase kan je per groep de samenwerkingskaders vastleggen.',
			],
			imageTitle: 'Kernvragen BIG 5',
			image: '/images/info/Kernvragen.png',
		},
	},
	{
		segmentGroup: SegmentGroup.Initiative,
		segment: Segment.ParticipationProcess,
		title: 'Participatieproces',
		type: 'component',
		component: DefineParticipationPlan,
	},
	{
		segmentGroup: SegmentGroup.Initiative,
		segment: Segment.CommunicationStrategy,
		title: 'Communicatiestrategie',
		type: 'text',
		// buttons: [
		//   {
		//     key: 'Templatecommunicatieplan',
		//     title: 'Template communicatieplan',
		//     filePath: '/downloads/',
		//     fileName: 'Template_communicatieplan.pdf',
		//   },
		// ],
		content: `<p>Goede participatie staat of valt met goede communicatie. Dit betekend dat we tijdig, begrijpelijk en volledig communiceren.</p>
      <p><b>Tijdig</b> betekend niet te vroeg en niet te laat, maar het juiste moment. Dit wordt bepaald door de ontvanger, de boodschap en beoogde effect. Ook betekend het dat we betrokkenen op de hoogte houden als er even niets te melden valt.
      </p> 
      <p><b>Begrijpelijk</b> betekend dat we op B1-niveau schrijven (zie <a target="_blank" href="https://www.communicatierijk.nl/vakkennis/rijkswebsites/aanbevolen-richtlijnen/taalniveau-b1">hier</a> en <a target="_blank" href="https://www.ishetb1.nl/">hier</a>).</p>
      <p><b>Volledig</b> betekend dat we iedereen op een toegankelijke manier van de juiste informatie voorzien.</p>
      <p>Hieronder kan je de template voor een communicatieplan downloaden. Betrek je communicatie adviseur bij het opstellen ervan. Ook op <a target="_blank" href="https://insite.nijmegen.nl/umbraco/direct-regelen/communicatiewinkel/">iNsite</a> vind je meer informatie over onze communicatie.</p>`,
	},
	/* eslint-disable */
	/* Definition Phase*/

	{
		segmentGroup: SegmentGroup.Definition,
		segment: Segment.Implementation,
		title: 'Uitvoeren participatieplan definitie fase',
		type: 'text',
		content: `<p>Geef uitvoering aan het participatieplan wat je in de initiatieffase heb opgesteld.</p>
      <p>Zorg dat je in de gaten houdt (meet) of je nog het juiste doet. Evalueer tijdig en stel waar dat voor een juist proces nodig is het plan bij.</p> 
      <p>Als je het plan bijstelt zorgt de partijen met het mandaat het weer opnieuw vaststellen. Bij Omgevings- en Gebiedsvisies worden college en raad over afwijkingen in het participatieproces geïnformeerd en wordt getoetst of de afwijkingen binnen de gestelde kaders vallen. Ook de betrokken participanten worden tijdig geïnformeerd</p>
      <h2>Participatieverslag</h2>
      <p>Van alle participatietrajecten wordt door de initiatiefnemer een verslag opgesteld. In dit verslag wordt beschreven wat de inbreng van deelnemers is geweest en wat hiermee is gedaan (ook als bijv. iets niet meegewogen is). Hiervan worden de betrokken burgerparticipanten op de hoogte gesteld. Als er geen participatieverslag is (bij een nieuw initiatief), dan wordt er geen besluit aan het college of de raad voorgelegd.</p>
      <h2>Ondersteuning</h2>
      <p>Hieronder volgen een aantal tools die je kunt gebruiken in de uitvoering van participatie. Participatie is altijd maatwerk, gebruik de tools om goede invulling te geven aan dit maatwerk.</p>`,
	},

	{
		segmentGroup: SegmentGroup.Definition,
		segment: Segment.CooperationFrame,
		title: 'Samenwerkingskaders',
		type: 'text',
		buttons: [
			{
				key: 'test',
				title: 'Template Samenwerkingskaders',
				filePath: '/downloads/',
				fileName: 'Format_teamwijzer.docx',
			},
		],
		content: `<p>Als je met een groep mensen samen gaat werken is het van belang vooraf te zorgen voor duidelijke afspraken over binnen welke kaders de groep functioneert. Dit noemen we de samenwerkingskaders. Hiervoor kun je een teamwijzer opstellen waarvan je de format hieronder kunt downloaden en invullen.</p>
      <p>In de teamwijzer geef je duidelijkheid over:</p>
      <ul>
        <li>Doel van het team</li>
        <li>Samenstelling van het team</li>
        <li>Representativiteit</li>
        <li>Domein en mandaat van het team</li>
        <li>Activiteiten van het team</li>
        <li>Teamorganisatie</li>
        <li>Evaluatie en Meting</li>
      </ul><br />`,
	},

	{
		segmentGroup: SegmentGroup.Definition,
		segment: Segment.Communication,
		title: 'Communicatie met de stad',
		type: 'text',
		content: `<p>Geef uitvoering aan het communicatieplan wat je in de initiatieffase heb opgesteld.</p>`,
	},

	{
		segmentGroup: SegmentGroup.Design,
		segment: Segment.Implementation,
		title: 'Uitvoeren participatieplan ontwerp fase',
		type: 'text',
		content: `<p>Geef uitvoering aan het participatieplan wat je in de initiatieffase heb opgesteld.</p>
    <p>Zorg dat je in de gaten houdt (meet) of je nog het juiste doet. Evalueer tijdig en stel waar dat voor een juist proces nodig is het plan bij. Zie hiervoor meer bij 'Meten en evalueren'.</p>
    <p>Als je het plan bijstelt zorgt de partijen met het mandaat het weer opnieuw vaststellen. Bij Omgevings- en Gebiedsvisies worden college en raad over afwijkingen in het participatieproces geïnformeerd en wordt getoetst of de afwijkingen binnen de gestelde kaders vallen. Ook de betrokken participanten worden tijdig geïnformeerd</p>
    <h2>Participatieverslag</h2>
    <p>Van alle participatietrajecten wordt door de initiatiefnemer een verslag opgesteld. In dit verslag wordt beschreven wat de inbreng van deelnemers is geweest en wat hiermee is gedaan (ook als bijv. iets niet meegewogen is). Hiervan worden de betrokken burgerparticipanten op de hoogte gesteld. Als er geen participatieverslag is (bij een nieuw initiatief), dan wordt er geen besluit aan het college of de raad voorgelegd.</p>
    <h2>Ondersteuning</h2>
    <p>Hieronder volgen een aantal tools die je kunt gebruiken in de uitvoering van participatie. Participatie is altijd maatwerk, gebruik de tools om goede invulling te geven aan dit maatwerk.</p>
    `,
	},

	{
		segmentGroup: SegmentGroup.Design,
		segment: Segment.Measure,
		title: 'Meten en evalueren ontwerp fase',
		type: 'text',
		content: `<p>Door tijdens de uitvoering van het participatieproces te meten of je nog het juiste doet voorkom je dat je te lang doorgaat met iets wat niet helpend is. Centraal bij een meting staan vragen als:</p>
    <ul>
      <li>Welke impact maken we? En is dat de juiste?</li>
      <li>Doe we het nog goed met elkaar? Zijn we tevreden over het proces?</li>
      <li>Zo niet: Wat zou er anders moeten?</li>
    </ul>
    <p>In welke vorm je deze meting doet hangt natuurlijk af van jouw specifieke participatieproces. Om tijdig te kunnen bijstellen zou je dit bij elk contactmoment met betrokkenen (even) ter sprake kunnen laten komen.</p>
    <p>Na afronding van een fase evalueer je de afgeronde fase. Evaluaties zijn er om te leren voor het vervolg of een volgend proces. Bij een tussentijdse evaluatie komen vrijwel altijd onderstaande punten ter sprake:</p>
    <ul>
      <li>Wat is je doelbereik vergeleken met de beginsituatie?</li>
      <li>Welke effecten heeft het proces tot nu toe?</li>
      <li>Welke aanpassingen moet je doen om je doelen beter te bereiken?</li>
      <li>Wat heb je tot nu toe geleerd van het proces en waar kun je dat toepassen?</li>
    </ul>
    <h2>Ondersteuning</h2>
    <p>Hieronder volgen een aantal tools die je kunt gebruiken in de uitvoering van een meting e/o evaluatie. Participatie is altijd maatwerk, gebruik de tools om goede invulling te geven aan dit maatwerk. </p>
    `,
	},

	{
		segmentGroup: SegmentGroup.Preparation,
		segment: Segment.Implementation,
		title: 'Uitvoeren participatieplan voorbereidings fase',
		type: 'text',
		content: `<p>Geef uitvoering aan het participatieplan wat je in de initiatieffase heb opgesteld.</p>
    <p>Zorg dat je in de gaten houdt (meet) of je nog het juiste doet. Evalueer tijdig en stel waar dat voor een juist proces nodig is het plan bij. Zie hiervoor meer bij 'Meten en evalueren'.</p>
    <p>Als je het plan bijstelt zorgt de partijen met het mandaat het weer opnieuw vaststellen. Bij Omgevings- en Gebiedsvisies worden college en raad over afwijkingen in het participatieproces geïnformeerd en wordt getoetst of de afwijkingen binnen de gestelde kaders vallen. Ook de betrokken participanten worden tijdig geïnformeerd</p>
    <h2>Participatieverslag</h2>
    <p>Van alle participatietrajecten wordt door de initiatiefnemer een verslag opgesteld. In dit verslag wordt beschreven wat de inbreng van deelnemers is geweest en wat hiermee is gedaan (ook als bijv. iets niet meegewogen is). Hiervan worden de betrokken burgerparticipanten op de hoogte gesteld. Als er geen participatieverslag is (bij een nieuw initiatief), dan wordt er geen besluit aan het college of de raad voorgelegd.</p>
    <h2>Ondersteuning</h2>
    <p>Hieronder volgen een aantal tools die je kunt gebruiken in de uitvoering van participatie. Participatie is altijd maatwerk, gebruik de tools om goede invulling te geven aan dit maatwerk.</p>
    `,
	},

	{
		segmentGroup: SegmentGroup.Preparation,
		segment: Segment.Measure,
		title: 'Meten en evalueren voorbereidings fase',
		type: 'text',
		content: `<p>Door tijdens de uitvoering van het participatieproces te meten of je nog het juiste doet voorkom je dat je te lang doorgaat met iets wat niet helpend is. Centraal bij een meting staan vragen als:</p>
    <ul>
      <li>Welke impact maken we? En is dat de juiste?</li>
      <li>Doe we het nog goed met elkaar? Zijn we tevreden over het proces?</li>
      <li>Zo niet: Wat zou er anders moeten?</li>
    </ul>
    <p>In welke vorm je deze meting doet hangt natuurlijk af van jouw specifieke participatieproces. Om tijdig te kunnen bijstellen zou je dit bij elk contactmoment met betrokkenen (even) ter sprake kunnen laten komen.</p>
    <p>Na afronding van een fase evalueer je de afgeronde fase. Evaluaties zijn er om te leren voor het vervolg of een volgend proces. Bij een tussentijdse evaluatie komen vrijwel altijd onderstaande punten ter sprake:</p>
    <ul>
      <li>Wat is je doelbereik vergeleken met de beginsituatie?</li>
      <li>Welke effecten heeft het proces tot nu toe?</li>
      <li>Welke aanpassingen moet je doen om je doelen beter te bereiken?</li>
      <li>Wat heb je tot nu toe geleerd van het proces en waar kun je dat toepassen?</li>
    </ul>
    <h2>Ondersteuning</h2>
    <p>Hieronder volgen een aantal tools die je kunt gebruiken in de uitvoering van een meting e/o evaluatie. Participatie is altijd maatwerk, gebruik de tools om goede invulling te geven aan dit maatwerk. </p>
    `,
	},

	{
		segmentGroup: SegmentGroup.Implementation,
		segment: Segment.Implementation,
		title: 'Uitvoeren participatieplan uitvoerings fase',
		type: 'text',
		content: `<p>Geef uitvoering aan het participatieplan wat je in de initiatieffase heb opgesteld.</p>
    <p>Zorg dat je in de gaten houdt (meet) of je nog het juiste doet. Evalueer tijdig en stel waar dat voor een juist proces nodig is het plan bij. Zie hiervoor meer bij 'Meten en evalueren'.</p>
    <p>Als je het plan bijstelt zorgt de partijen met het mandaat het weer opnieuw vaststellen. Bij Omgevings- en Gebiedsvisies worden college en raad over afwijkingen in het participatieproces geïnformeerd en wordt getoetst of de afwijkingen binnen de gestelde kaders vallen. Ook de betrokken participanten worden tijdig geïnformeerd</p>
    <h2>Participatieverslag</h2>
    <p>Van alle participatietrajecten wordt door de initiatiefnemer een verslag opgesteld. In dit verslag wordt beschreven wat de inbreng van deelnemers is geweest en wat hiermee is gedaan (ook als bijv. iets niet meegewogen is). Hiervan worden de betrokken burgerparticipanten op de hoogte gesteld. Als er geen participatieverslag is (bij een nieuw initiatief), dan wordt er geen besluit aan het college of de raad voorgelegd.</p>
    <h2>Ondersteuning</h2>
    <p>Hieronder volgen een aantal tools die je kunt gebruiken in de uitvoering van participatie. Participatie is altijd maatwerk, gebruik de tools om goede invulling te geven aan dit maatwerk.</p>
    `,
	},

	{
		segmentGroup: SegmentGroup.Implementation,
		segment: Segment.Measure,
		title: 'Meten en evalueren uitvoerings fase',
		type: 'text',
		content: `<p>Door tijdens de uitvoering van het participatieproces te meten of je nog het juiste doet voorkom je dat je te lang doorgaat met iets wat niet helpend is. Centraal bij een meting staan vragen als:</p>
    <ul>
      <li>Welke impact maken we? En is dat de juiste?</li>
      <li>Doe we het nog goed met elkaar? Zijn we tevreden over het proces?</li>
      <li>Zo niet: Wat zou er anders moeten?</li>
    </ul>
    <p>In welke vorm je deze meting doet hangt natuurlijk af van jouw specifieke participatieproces. Om tijdig te kunnen bijstellen zou je dit bij elk contactmoment met betrokkenen (even) ter sprake kunnen laten komen.</p>
    <p>Na afronding van een fase evalueer je de afgeronde fase. Evaluaties zijn er om te leren voor het vervolg of een volgend proces. Bij een tussentijdse evaluatie komen vrijwel altijd onderstaande punten ter sprake:</p>
    <ul>
      <li>Wat is je doelbereik vergeleken met de beginsituatie?</li>
      <li>Welke effecten heeft het proces tot nu toe?</li>
      <li>Welke aanpassingen moet je doen om je doelen beter te bereiken?</li>
      <li>Wat heb je tot nu toe geleerd van het proces en waar kun je dat toepassen?</li>
    </ul>
    <h2>Ondersteuning</h2>
    <p>Hieronder volgen een aantal tools die je kunt gebruiken in de uitvoering van een meting e/o evaluatie. Participatie is altijd maatwerk, gebruik de tools om goede invulling te geven aan dit maatwerk. </p>
    `,
	},

	{
		segmentGroup: SegmentGroup.Aftercare,
		segment: Segment.Implementation,
		title: 'Uitvoeren participatieplan nazorg fase',
		type: 'text',
		content: `<p>Geef uitvoering aan het participatieplan wat je in de initiatieffase heb opgesteld.</p>
    <p>Zorg dat je in de gaten houdt (meet) of je nog het juiste doet. Evalueer tijdig en stel waar dat voor een juist proces nodig is het plan bij. Zie hiervoor meer bij 'Meten en evalueren'.</p>
    <p>Als je het plan bijstelt zorgt de partijen met het mandaat het weer opnieuw vaststellen. Bij Omgevings- en Gebiedsvisies worden college en raad over afwijkingen in het participatieproces geïnformeerd en wordt getoetst of de afwijkingen binnen de gestelde kaders vallen. Ook de betrokken participanten worden tijdig geïnformeerd</p>
    <h2>Participatieverslag</h2>
    <p>Van alle participatietrajecten wordt door de initiatiefnemer een verslag opgesteld. In dit verslag wordt beschreven wat de inbreng van deelnemers is geweest en wat hiermee is gedaan (ook als bijv. iets niet meegewogen is). Hiervan worden de betrokken burgerparticipanten op de hoogte gesteld. Als er geen participatieverslag is (bij een nieuw initiatief), dan wordt er geen besluit aan het college of de raad voorgelegd.</p>
    <h2>Ondersteuning</h2>
    <p>Hieronder volgen een aantal tools die je kunt gebruiken in de uitvoering van participatie. Participatie is altijd maatwerk, gebruik de tools om goede invulling te geven aan dit maatwerk.</p>
    `,
	},

	{
		segmentGroup: SegmentGroup.Aftercare,
		segment: Segment.Celebration,
		title: 'Vieren gezamelijk resultaat',
		type: 'text',
		content: `<p>Nu het proces klaar is, is het waardevol even stil te staan bij het resultaat. Er zullen vast dingen anders zijn gegaan dan bedacht of gewenst. En toch heeft iedereen er op zijn of haar wijze energie in gestoken om er het mooiste van te maken. Vier deze inspanning, de leermomenten, de hobbels en de resultaten die er zijn en waren. Zo sluit je het proces samen goed af en ontstaat er weer ruimte voor een volgend initiatief.</p>`,
	},

	{
		segmentGroup: SegmentGroup.Aftercare,
		segment: Segment.Agreement,
		title: 'Participatieovereenkomst',
		type: 'text',
		content: `<p>Het kan dat het fysieke resultaat van het participatieproces (of een deel ervan) in gebruik of beheer wordt genomen door (een groep) inwoners. In dat geval wordt dit overdrachtsmoment vergezeld van een participatieovereenkomst. Hierin worden afspraken vastgelegd over het gebruik, beheer en onderhoud.</p>
    <p>Neem contact op met de <a target="_blank" href="https://www.nijmegen.nl/diensten/wijken/wijkregisseurs/">betreffende wijkregisseur</a> om dit document op te stellen.</p>`,
	},

	{
		segmentGroup: SegmentGroup.Aftercare,
		segment: Segment.Evaluation,
		title: 'Eindevaluatie',
		type: 'text',
		content: `<p>Na afronding van het hele proces evalueer je de het doorlopen participatieproces. Evaluaties zijn er om te leren voor een volgend proces. Bij een eindevaluatie komen vrijwel altijd onderstaande punten ter sprake:</p>
     <ul>
        <li>Wat is je doelbereik vergeleken met de beginsituatie en halverwege het proces?</li>
        <li>Welke effecten heeft het proces?</li>
        <li>Wat zijn succesfactoren van het proces?</li>
        <li>Wat heb je geleerd van het proces (verbeterpunten) en waar kun je dat toepassen?</li>
     </ul>
     <h2>Ondersteuning</h2>
     <p>Hieronder volgen een aantal tools die je kunt gebruiken in de uitvoering van een meting e/o evaluatie. Participatie is altijd maatwerk, gebruik de tools om goede invulling te geven aan dit maatwerk.</p>
    `,
	},
];
