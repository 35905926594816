import { FC } from 'react';
import styles from './StatusIcon.module.css';
import AppIcon from '../AppIcon/AppIcon';
import { Status } from '../../types';
import Icon from '@mdi/react';
import { mdiAccountCheck, mdiAccountClock, mdiAccountCancel } from '@mdi/js';

const StatusIcon: FC<{
	status: Status;
	showStatusAsText?: boolean;
	children?: React.ReactNode;
}> = ({ status, showStatusAsText = false, children }) => (
	<div className={styles.StatusIcon}>
		{
			{
				[Status.Done]: (
					<AppIcon
						icon={
							<Icon
								path={mdiAccountCheck}
								size={1}
								className={styles.StatusIconVerified}
							/>
						}
					>
						{showStatusAsText ? 'voltooid' : children}
					</AppIcon>
				),
				[Status.NoStatus]: (
					<AppIcon
						icon={
							<Icon
								path={mdiAccountCancel}
								size={1}
								className={styles.StatusIconNoStatus}
							/>
						}
					>
						{showStatusAsText ? 'geen' : children}
					</AppIcon>
				),
				[Status.Waiting]: (
					<AppIcon
						icon={
							<Icon
								path={mdiAccountClock}
								size={1}
								className={styles.StatusIconWaiting}
							/>
						}
					>
						{showStatusAsText ? 'afwachting' : children}
					</AppIcon>
				),
			}[status]
		}
	</div>
);

export default StatusIcon;
