import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import styles from './Stakeholders.module.css';
import {
	Plan,
	Segment,
	SegmentGroup,
	Size,
	Stakeholder,
	Status,
} from '../../../types';
import SidePanel from '../../../components/SidePanel/SidePanel';
import AppInput from '../../../components/AppInput/AppInput';
import AppButton from '../../../components/Button/AppButton';
import AppIcon from '../../../components/AppIcon/AppIcon';
import Icon from '@mdi/react';
import {
	mdiAccountOutline,
	mdiAccountPlus,
	mdiCog,
	mdiDelete,
	mdiContentSave,
	mdiPlusBox,
} from '@mdi/js';
import slice, {
	mapSliced,
	StateUpdater,
	SlicedElement,
} from '../../../StateManagement';
import { MoreInfoButton } from '../TextBox/TextBox';
import { SegmentTemplate } from '../../../segments';
import { setStatus } from '../../../components/Status/Status';
import { useAuthenticatedFetch } from '../../../auth';

const API_URL = process.env.REACT_APP_BACKEND;

const Stakeholders: FC<{
	plan: Plan;
	setPlan: Dispatch<SetStateAction<Plan>>;
	template: SegmentTemplate;
}> = ({ plan, setPlan, template }) => {
	const AddStakeHolder = (newValue: Stakeholder) => {
		setStatus(
			setPlan,
			SegmentGroup.Initiative,
			Segment.Stakeholders,
			Status.Done
		);
		return slice(setPlan, 'stakeholders')([...plan.stakeholders, newValue]);
	};

	return (
		<div className={styles.StakeholderManagement}>
			<h2 className="h1-style">{template.title}</h2>
			<table>
				<thead>
					<tr>
						{/* <th className={styles.SmallTh}></th> */}
						<th scope="colgroup" colSpan={2}>
							Partij
							<MoreInfoButton onlyIcon={true} tooltip={true}>
								Omschrijf de belanghebbende partijen. Bijvoorbeeld: omwonenden,
								wijkraad, gemeenteraad, fietsersbond, adviescommissie...
							</MoreInfoButton>
						</th>
						<th scope="col">
							Belang
							<MoreInfoButton onlyIcon={true} tooltip={true}>
								Omschrijf het belang van de partij. Bijvoorbeeld: inspraak bij
								ontwerp, behouden van [...] of vaststellen kaders voor [...]
							</MoreInfoButton>
						</th>
						<th scope="col">
							Naam
							<MoreInfoButton onlyIcon={true} tooltip={true}>
								Wie is er vanuit de partij betrokken. Dit kan een
								contactpersoon of sleutelfiguur zijn. Voor elke belangrijke
								betrokkene maak je een nieuwe regel aan.
							</MoreInfoButton>
						</th>
						<th scope="col">
							Rol(len)
							<MoreInfoButton onlyIcon={true} tooltip={true}>
								Omschrijf de rol(len) die de persoon heeft (in zijn of haar
								partij in relatie tot dit initiatief).
							</MoreInfoButton>
						</th>
						<th scope="col">
							Domein
							<MoreInfoButton onlyIcon={true} tooltip={true}>
								Omschrijf het domein dat de persoon binnen zijn of haar partij
								en in relatie tot dit initiatief heeft. Domein betekend: het
								gebied van handelen.
							</MoreInfoButton>
						</th>
						<th scope="colgroup" colSpan={2}>
							Mandaten
							<MoreInfoButton onlyIcon={true} tooltip={true}>
								Omschrijf de mandaten die de persoon heeft binnen zijn of haar
								partij en in relatie tot dit initiatief. Mandaat betekend: de
								bevoegdheid van handelen binnen het domein (gebied).
							</MoreInfoButton>
						</th>
					</tr>
				</thead>
				<tbody>
					{mapSliced(
						plan.stakeholders,
						slice(setPlan, 'stakeholders'),
						(props, index: number) => (
							<tr key={index}>
								<td>
									<AppIcon
										icon={<Icon path={mdiAccountOutline} size={1} />}
									></AppIcon>
								</td>
								<td>{props.value.description}</td>
								<td>{props.value.interest}</td>
								<td>{props.value.name}</td>
								<td>{props.value.role}</td>
								<td>{props.value.domain}</td>
								<td>{props.value.mandate}</td>
								<td className={styles.TableButtonsContainer}>
									<EditSidePanelButton
										plan={plan}
										stakeholder={props.value}
										{...props}
									/>
									<DeleteSidePanelButton
										plan={plan}
										stakeholder={props.value}
										remove={props.remove}
									/>
								</td>
							</tr>
						)
					)}
				</tbody>
			</table>
			<AddSidePanelButton plan={plan} addStakeholder={AddStakeHolder} />
		</div>
	);
};

const AddSidePanelButton: FC<{
	plan: Plan;
	addStakeholder: (newValue: Stakeholder) => void;
}> = ({ plan, addStakeholder }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<UpsertSidePanel
				plan={plan}
				open={open}
				setOpen={setOpen}
				append={addStakeholder}
			/>
			<AppButton onClick={() => setOpen(true)}>
				<AppIcon
					icon={<Icon path={mdiAccountPlus} size={1} />}
					size={Size.Large}
					iconRight
				>
					BELANGHEBBENDE AANMAKEN
				</AppIcon>
			</AppButton>
		</>
	);
};

const EditSidePanelButton: FC<
	{
		plan: Plan;
		stakeholder: Stakeholder;
	} & SlicedElement<Stakeholder>
> = ({ plan, stakeholder, setValue, append }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<UpsertSidePanel
				plan={plan}
				stakeholder={stakeholder}
				open={open}
				setOpen={setOpen}
				setValue={setValue}
				append={append}
			/>
			<div onClick={() => setOpen(true)}>
				<Icon path={mdiCog} size={1} className={styles.TableActionButton} />
			</div>
		</>
	);
};

const UpsertSidePanel: FC<{
	plan: Plan;
	stakeholder?: Stakeholder;
	open: boolean;
	setOpen: (open: boolean) => void;
	append?: (newValue: Stakeholder) => void;
	setValue?: StateUpdater<Stakeholder>;
}> = ({ plan, stakeholder, open, setOpen, append, setValue }) => {
	// eslint-disable-next-line
	const [editedStakeholder, setStakeholder] = useState<Stakeholder>(
		stakeholder ?? {
			stakeholderId: 0,
			name: '',
			role: '',
			domain: '',
			interest: '',
			mandate: '',
			description: '',
		}
	);

	useEffect(() => {
		setStakeholder(
			stakeholder ?? {
				stakeholderId: 0,
				name: '',
				role: '',
				domain: '',
				interest: '',
				mandate: '',
				description: '',
			}
		);
	}, [plan, stakeholder]);

	const authenticatedFetch = useAuthenticatedFetch();
	const editStakeholder = () => {
		// ToDO: Michiel - THis gives 404
		authenticatedFetch(`${API_URL}/plan/${plan.planId}/stakeholder`, {
			method: stakeholder ? 'PUT' : 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify(editedStakeholder),
		})
			.then((r) => r.json())
			.then((r) => (stakeholder == null ? append?.(r) : setValue?.(r)))
			.then(() => setOpen(false));
	};

	return (
		<SidePanel
			title={
				stakeholder
					? 'Belanghebbende aanpassen'
					: 'Nieuwe belanghebbende toevoegen'
			}
			open={open}
			setOpen={setOpen}
		>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					editStakeholder();
				}}
			>
				<AppInput
					autoFocus
					label={'Partij'}
					placeholder="Partij"
					value={editedStakeholder.description}
					onChange={(event) => {
						setStakeholder({
							...editedStakeholder,
							description: event.target.value,
						});
					}}
					required
				/>
				<AppInput
					label={'Belang'}
					placeholder="Belang"
					value={editedStakeholder.interest}
					onChange={(event) => {
						setStakeholder({
							...editedStakeholder,
							interest: event.target.value,
						});
					}}
				/>
				<AppInput
					label={'Naam'}
					placeholder="Naam"
					value={editedStakeholder.name}
					onChange={(event) => {
						setStakeholder({
							...editedStakeholder,
							name: event.target.value,
						});
					}}
				/>
				<AppInput
					label={'Rol(len)'}
					placeholder="Rol(len)"
					value={editedStakeholder.role}
					onChange={(event) => {
						setStakeholder({
							...editedStakeholder,
							role: event.target.value,
						});
					}}
				/>
				<AppInput
					label={'Domein'}
					placeholder="Domein"
					value={editedStakeholder.domain}
					onChange={(event) => {
						setStakeholder({
							...editedStakeholder,
							domain: event.target.value,
						});
					}}
				/>
				<AppInput
					label={'Mandaten'}
					placeholder="Mandaten"
					value={editedStakeholder.mandate}
					onChange={(event) => {
						setStakeholder({
							...editedStakeholder,
							mandate: event.target.value,
						});
					}}
				/>
				<AppButton type={'submit'}>
					<AppIcon
						icon={
							<Icon
								path={stakeholder ? mdiContentSave : mdiPlusBox}
								size={1}
							/>
						}
						iconRight
					>
						{stakeholder ? 'Opslaan' : 'Toevoegen'}
					</AppIcon>
				</AppButton>
			</form>
		</SidePanel>
	);
};

const DeleteSidePanelButton: FC<{
	plan: Plan;
	stakeholder: Stakeholder;
	remove: () => void;
}> = ({ plan, stakeholder, remove }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<DeleteSidePanel
				plan={plan}
				stakeholder={stakeholder}
				open={open}
				setOpen={setOpen}
				remove={remove}
			/>
			<div onClick={() => setOpen(true)}>
				<Icon path={mdiDelete} size={1} className={styles.TableActionButton} />
			</div>
		</>
	);
};

const DeleteSidePanel: FC<{
	plan: Plan;
	stakeholder: Stakeholder;
	open: boolean;
	setOpen: (open: boolean) => void;
	remove: () => void;
}> = ({ plan, stakeholder, open, setOpen, remove }) => {
	const authenticatedFetch = useAuthenticatedFetch();
	const deleteStakeholder = () => {
		// ToDO: Michiel - THis gives 404
		authenticatedFetch(
			`${API_URL}/plan/${plan.planId}/stakeholder/${stakeholder.stakeholderId}`,
			{
				method: 'DELETE',
			}
		)
			.then(() => remove())
			.then(() => setOpen(false));
	};

	return (
		<SidePanel title={'Stakeholder verwijderen'} open={open} setOpen={setOpen}>
			<div>
				Weet u zeker dat u &ldquo;{stakeholder.name}&rdquo; wilt verwijderen?
			</div>
			<AppButton onClick={() => deleteStakeholder()} autoFocus>
				Verwijderen
			</AppButton>
		</SidePanel>
	);
};

export default Stakeholders;
