import React, { createContext } from 'react';
import { Plan } from '../types';

export type PlanContextProps = {
	plan?: Plan;
	setPlan: React.Dispatch<React.SetStateAction<Plan>>;
	setPlanFromBackend: React.Dispatch<Plan>;
};

export const PlanContext = createContext<PlanContextProps>({
	plan: undefined,
	setPlan: () => {
		console.log('FIX ME');
	},
	setPlanFromBackend: () => {
		console.log('FIX ME');
	},
});
