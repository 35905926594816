import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './SideBar.module.css';
import {
	Size,
	SegmentGroup,
	Segment,
	Status,
	SegmentStatus,
} from '../../types';
import { PlanContext, PlanContextProps } from '../../contexts/PlanContext';
import AppIcon from '../AppIcon/AppIcon';
import AppButton from '../Button/AppButton';
import StatusIcon from '../StatusIcon/StatusIcon';

import { segmentGroups, segments } from '../../segments';

import Icon from '@mdi/react';
import { mdiChevronRight, mdiExport, mdiLoading } from '@mdi/js';
import SidePanel from '../SidePanel/SidePanel';
import { useAuthenticatedFetch } from '../../auth';
import StepLink from '../NavBar/StepLink';

const API_URL = process.env.REACT_APP_BACKEND;

const saveFile = (file: Blob) => {
	const url = window.URL.createObjectURL(new Blob([file]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `Participatieplan.docx`);

	// Append to html link element page
	document.body.appendChild(link);

	// Start download
	link.click();

	// Clean up and remove the link
	link.parentNode?.removeChild(link);
};

const SideBar: FC<{
	activeSegmentGroup?: SegmentGroup;
	activeSegment?: Segment;
	children?: React.ReactNode;
}> = ({ activeSegmentGroup, activeSegment, children }) => {
	const { plan } = useContext<PlanContextProps>(PlanContext);
	const [openSegmentGroup, setOpenSegmentGroup] = useState<
		SegmentGroup | undefined
	>();

	useEffect(
		() => setOpenSegmentGroup(activeSegmentGroup),
		[activeSegmentGroup]
	);

	const [errorOpen, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const authenticatedFetch = useAuthenticatedFetch();

	async function downloadPlan() {
		if (loading) return;

		setLoading(true);
		return authenticatedFetch(`${API_URL}/plan/${plan?.planId}/document`, {
			method: 'POST',
			headers: { responseType: 'blob' },
		})
			.then(async (r) => {
				if (r.ok) return r.blob();
				const json = await r.json();
				return await Promise.reject(json);
			})
			.then((r) => saveFile(r))
			.catch(() => setOpen(true))
			.finally(() => setLoading(false));
	}

	return (
		<>
			<div className={styles.SideBar}>
				{children}
				{plan && (
					<>
						<h1>{plan.title}</h1>
						<div className={styles.SideBarPlan}>
							{segmentGroups.map(({ segmentGroup, title }, i) => (
								<Collapse
									key={i}
									segmentGroup={segmentGroup}
									planId={plan.planId}
									segmentStatuses={plan.segmentStatuses}
									title={title}
									isOpen={segmentGroup == openSegmentGroup}
									setOpen={() => setOpenSegmentGroup(segmentGroup)}
									activeSegment={activeSegment}
								/>
							))}
							<div className={styles.SideBarButtonContainer}>
								<AppButton
									className={
										styles.SideBarButton +
										' ' +
										(loading ? styles.LoadingButton : '')
									}
									onClick={downloadPlan}
									role="button"
								>
									<AppIcon
										icon={
											loading ? (
												<Icon
													path={mdiLoading}
													spin
													size={1}
													className={styles.DownloadButtonLoader}
												/>
											) : (
												<Icon
													path={mdiExport}
													size={1}
													className={styles.DownloadButtonIcon}
												/>
											)
										}
										iconRight={true}
										spaceBetween={true}
										size={Size.Large}
									>
										{!loading ? 'DOWNLOAD PARTICIPATIEPLAN' : ''}
									</AppIcon>
								</AppButton>
							</div>
						</div>
					</>
				)}
			</div>
			{errorOpen && (
				<ErrorPanel setOpen={setOpen}>
					Er is een fout opgetreden bij het genereren van het document. Neem
					contact met de beheerder op als dit zich vaker voor doet.
				</ErrorPanel>
			)}
		</>
	);
};

const ErrorPanel: FC<{
	title?: string;
	children: React.ReactNode;
	setOpen: (open: boolean) => void;
}> = ({ children, setOpen }) => {
	return (
		<SidePanel title="Er is iets mis gegaan" open={true} setOpen={setOpen}>
			{children}
		</SidePanel>
	);
};

const Collapse: FC<{
	segmentGroup: SegmentGroup;
	segmentStatuses: SegmentStatus[];
	title: string;
	planId: number;
	isOpen: boolean;
	setOpen: () => void;
	activeSegment: Segment | undefined;
	disabled?: boolean;
}> = ({
	segmentGroup,
	segmentStatuses,
	title,
	planId,
	isOpen,
	setOpen,
	activeSegment,
	disabled = false,
}) => (
	<div className={styles.Collapse}>
		<CollapseHead isActive={isOpen} onClick={setOpen} disabled={disabled}>
			{title}
		</CollapseHead>
		<div
			className={` ${styles.Collapsed} 
        ${isOpen ? styles.Extended : ''}`}
		>
			{segments
				.filter((x) => x.segmentGroup === segmentGroup)
				.map(({ segment, segmentGroup, title }, i) => (
					<CollapseItem
						segmentGroup={segmentGroup}
						segment={segment}
						title={title}
						status={
							segmentStatuses.find(
								(x) => x.segmentGroup == segmentGroup && x.segment === segment
							)?.status ?? Status.NoStatus
						}
						planId={planId}
						isActive={isOpen && segment === activeSegment}
						key={i}
						handleFocus={() => !i && setOpen()}
					/>
				))}
		</div>
	</div>
);

const CollapseHead: FC<{
	isActive: boolean;
	onClick?: () => void;
	disabled: boolean;
	children: React.ReactNode;
}> = ({ isActive, onClick, disabled, children }) => (
	<div
		className={`${styles.CollapseHead} ${
			isActive ? !disabled && styles.CollapseHeadOpen : ''
		} ${disabled ? styles.CollapseHeadDisabled : ''}`}
		onClick={onClick}
	>
		{!disabled ? (
			<AppIcon
				icon={
					<Icon
						path={mdiChevronRight}
						size={1}
						className={isActive ? styles.Rotate : ''}
					/>
				}
				size={Size.Small}
				iconRight={true}
				spaceBetween={true}
			>
				{children}
			</AppIcon>
		) : (
			<>{children}</>
		)}
	</div>
);

const CollapseItem: FC<{
	segmentGroup: SegmentGroup;
	segment: Segment;
	title: string;
	status: Status;
	planId: number;
	isActive: boolean;
	handleFocus: () => void;
}> = ({
	segmentGroup,
	segment,
	title,
	status,
	planId,
	isActive,
	handleFocus,
}) => (
	<div className={`${styles.CollapseItem} ${styles.CollapseItemOpen} `}>
		<StatusIcon status={status}>
			<span className={isActive ? styles.CollapseItemOpenActive : ''}>
				<StepLink
					onFocus={handleFocus}
					planId={planId}
					segmentGroup={segmentGroup}
					segment={segment}
					title={title}
				/>
			</span>
		</StatusIcon>
	</div>
);

export default SideBar;
