import { useEffect, useState } from 'react';
import styles from './Pagination.module.css';
import { Size } from '../../types';
import ReactPaginate from 'react-paginate';
import AppIcon from '../AppIcon/AppIcon';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

const ITEMSPERPAGE = 5;
const PAGESSHOWN = 2; // The range of pages displayed.
const MARGINPAGES = 2; // The number of pages to display for margins on the sides

const Pagination = <T,>({
	items,
	setItems,
}: {
	items: T[];
	setItems: (items: T[]) => void;
}): JSX.Element => {
	const [itemOffset, setItemOffset] = useState(0);
	const [pageCount, setPageCount] = useState(0);

	const handlePageClick = (event: { selected: number }) => {
		const newOffset = (event.selected * ITEMSPERPAGE) % items.length;
		setItemOffset(newOffset);
	};

	useEffect(() => {
		const endOffset = itemOffset + ITEMSPERPAGE;
		setPageCount(Math.ceil(items.length / ITEMSPERPAGE));
		setItems(
			items.length > ITEMSPERPAGE ? items.slice(itemOffset, endOffset) : items
		);
	}, [itemOffset, items, setItems]);

	return items.length > ITEMSPERPAGE ? (
		<ReactPaginate
			onPageChange={handlePageClick}
			pageRangeDisplayed={PAGESSHOWN}
			marginPagesDisplayed={MARGINPAGES}
			pageCount={pageCount}
			previousLabel={
				<AppIcon
					icon={<Icon path={mdiChevronLeft} size={1} />}
					size={Size.Small}
				>
					Vorige
				</AppIcon>
			}
			breakLabel="..."
			nextLabel={
				<AppIcon
					icon={<Icon path={mdiChevronRight} size={1} />}
					size={Size.Small}
					iconRight
				>
					Volgende
				</AppIcon>
			}
			containerClassName={styles.Pagination}
			pageClassName={styles.PageItem}
			pageLinkClassName={styles.PageLink}
			previousClassName={styles.PageItem}
			previousLinkClassName={styles.PageLink}
			nextClassName={styles.PageItem}
			nextLinkClassName={styles.PageLink}
			breakClassName={styles.PageItem}
			breakLinkClassName={styles.PageLink}
			activeClassName={styles.Active}
			renderOnZeroPageCount={() => null}
			disabledClassName={styles.DisabledPageItem}
			disabledLinkClassName={styles.DisabledPageLink}
			ariaLabelBuilder={(page: number) => `Pagina ${page}`}
			previousAriaLabel="Vorige pagina"
			nextAriaLabel="Volgende pagina"
		/>
	) : (
		<></>
	);
};

export default Pagination;
