import React, { FC } from 'react';
import { Size } from '../../types';
import AppIcon from '../AppIcon/AppIcon';
import styles from './Accordion.module.css';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import styled from 'styled-components';

export const StyledContent = styled.div`
	a {
		text-decoration: underline;
	}

	a:hover {
		text-decoration: none;
	}
`;

const Accordion: FC<{
	title: string;
	isOpen: boolean;
	setOpen: (open: boolean) => void;
	setActiveItem: (active: number) => void;
	id: number;
	disabled?: boolean;
	children: React.ReactNode;
	showHeader?: boolean;
	showStyling?: boolean;
	className?: string;
}> = ({
	title,
	isOpen,
	id,
	setOpen,
	setActiveItem,
	disabled = false,
	showHeader = true,
	showStyling = true,
	className,
	children,
}) => {
	return (
		<div
			className={`${styles.Accordion} ${className ? className : ''} ${
				showStyling ? styles.ShowStyling : ''
			}`}
		>
			{showHeader && (
				<AccordionHeader
					isActive={isOpen}
					onClick={() => {
						setOpen(!isOpen);
						setActiveItem(id);
					}}
					disabled={disabled}
				>
					{title}
				</AccordionHeader>
			)}
			<StyledContent
				className={` 
        ${styles.AccordionContent} 
        ${styles.Collapsed} 
        ${isOpen ? styles.Extended : ''}`}
			>
				{children}
			</StyledContent>
		</div>
	);
};

const AccordionHeader: FC<{
	isActive: boolean;
	onClick?: () => void;
	disabled: boolean;
	children: React.ReactNode;
}> = ({ isActive, onClick, disabled, children }) => (
	<div
		className={`${styles.AccordionHeader} ${
			isActive ? !disabled && styles.AccordionHeaderOpen : ''
		} ${disabled ? styles.AccordionHeaderDisabled : ''}`}
		onClick={onClick}
	>
		{!disabled ? (
			<AppIcon
				icon={
					<Icon
						path={mdiChevronRight}
						size={1}
						className={isActive ? styles.Rotate : ''}
					/>
				}
				size={Size.Small}
				iconRight={true}
				spaceBetween={true}
			>
				{children}
			</AppIcon>
		) : (
			<div className={`${styles.AccordionItem} ${styles.AccordionItemOpen} `}>
				{children}
			</div>
		)}
	</div>
);

// const Accordion: FC<{
//   isOpen: boolean;
//   setOpen: (open: boolean) => void;
//   setActivePhase: (active: number) => void;
//   activeStep?: number;
//   disabled?: boolean;
// }> = ({
//   isOpen,
//   setOpen,
//   setActivePhase,
//   activeStep,
//   disabled = false,
// }) => (
//   <div className={styles.Collapse}>
//     <CollapseHead
//       isActive={isOpen}
//       onClick={() => {
//         setOpen(!isOpen);
//         setActivePhase(phase.processPhaseId);
//       }}
//       disabled={disabled}
//     >
//       {phase.title}
//     </CollapseHead>
//     <div
//       className={` ${styles.Collapsed}
//           ${isOpen ? styles.Extended : ''}`}
//     >
//       {phase.processSteps.map((step, i) => (
//         <CollapseItem
//           phase={phase}
//           step={step}
//           isActive={step.processStepId == activeStep}
//           key={i}
//         />
//       ))}
//     </div>
//   </div>
// );

// const CollapseHead: FC<{
//   isActive: boolean;
//   onClick?: () => void;
//   disabled: boolean;
// }> = ({ isActive, onClick, disabled, children }) => (
//   <div
//     className={`${styles.CollapseHead} ${
//       isActive ? !disabled && styles.CollapseHeadOpen : ''
//     } ${disabled ? styles.CollapseHeadDisabled : ''}`}
//     onClick={onClick}
//   >
//     {!disabled ? (
//       <AppIcon
//         icon={<ChevronIcon className={isActive ? styles.Rotate : ''} />}
//         size={Size.Small}
//         iconRight={true}
//         spaceBetween={true}
//       >
//         {children}
//       </AppIcon>
//     ) : (
//       <>{children}</>
//     )}
//   </div>
// );

// const CollapseItem: FC<{
//   phase: ProcessPhase;
//   step: ProcessStep;
//   isActive: boolean;
// }> = ({ phase, step, isActive }) => (
//   <div className={`${styles.CollapseItem} ${styles.CollapseItemOpen} `}>
//     <StatusIcon status={step.status}>
//       <span className={isActive ? styles.CollapseItemOpenActive : ''}>
//         <StepLink phase={phase} step={step} sub={true} />
//       </span>
//     </StatusIcon>
//   </div>
// );

export default Accordion;
