// --- models --- //

export type Plan = {
	planId: number;
	title: string;
	user: User;
	segmentStatuses: SegmentStatus[];
	stakeholders: Stakeholder[];
	stakeholderGroups: StakeholderGroup[];
	steps: Step[];
	texts: Text[];
	creationDate: string;
};

export type User = {
	userId: string;
	name: string;
};

export interface SegmentStatus {
	planId: number;
	segmentGroup: SegmentGroup;
	segment: Segment;
	status: Status;
}

export enum Status {
	Waiting = 'waiting',
	NoStatus = 'noStatus',
	Done = 'done',
}

export type Stakeholder = {
	stakeholderId: number;
	description: string;
	interest: string;
	name: string;
	role: string;
	domain: string;
	mandate: string;
};

export type StakeholderGroup = {
	stakeholderGroupId: number;
	name: string;
	stakeholders: Stakeholder[];
};

export type Image = {
	imageId: number;
	content: Uint8Array;
	altText: string;
};

export type Step = {
	stepId: number;
	name: string;
	subSteps: SubStep[];
	order: number;
	milestone: boolean;
};

export type SubStep = {
	subStepId: number;
	name: string;
	dateFrom?: string;
	dateTo?: string;
	period?: string;
	subStepStakeholderGroups: SubStepStakeholderGroup[];
	order: number;
	milestone: boolean;
	domains?: string;
};

export interface Text {
	planId: number;
	segmentGroup: SegmentGroup;
	segment: Segment;
	key: string;
	content: string;
}

export type SubStepStakeholderGroup = {
	subStepStakeholderGroupId: number;
	subStepId: number;
	stakeholderGroupId: number;
	mandate?: Mandate;
	domains?: string;
};

// --- enums --- //

export enum Size {
	ExtraSmall = 'Xs',
	Small = 'Sm',
	Large = 'Lg',
}

export enum Mandate {
	Inform = 'inform',
	Consult = 'consult',
	Advice = 'advice',
	CoDecide = 'coDecide',
}

export const MandateTranslations = {
	inform: 'Informeren',
	consult: 'Raadplegen',
	advice: 'Adviseren',
	coDecide: 'Meebeslissen',
};

//Note: also change this in Segment.cs in back-end
export enum SegmentGroup {
	Initiative = 'initiative',
	Definition = 'definition',
	Design = 'design',
	Preparation = 'preparation',
	Implementation = 'implementation',
	Aftercare = 'aftercare',
}

//Note: also change this in Segment.cs in back-end
export enum Segment {
	Goal = 'goal',
	ExploratoryPreliminaryPhase = 'exploratoryPreliminaryPhase',
	History = 'history',
	Stakeholders = 'stakeholders',
	OrganiseStakeholders = 'organiseStakeholders',
	EnvironmentImpact = 'environmentImpact',
	ParticipationProcess = 'participationProcess',
	CommunicationStrategy = 'communicationStrategy',
	Implementation = 'implementation',
	CooperationFrame = 'cooperationFrame',
	Communication = 'communication',
	Measure = 'measure',
	Celebration = 'celebration',
	Agreement = 'agreement',
	Evaluation = 'evaluation',
}
