import { FC } from 'react';
import styled from 'styled-components';
import { useAuthenticatedFetch } from '../../auth';
import { segments, segmentGroups } from '../../segments';
import { StateUpdater } from '../../StateManagement';
import {
	Plan,
	Segment,
	SegmentGroup,
	SegmentStatus,
	Size,
	Status,
} from '../../types';
import AppCheckbox from '../AppCheckbox/AppCheckbox';

const API_URL = process.env.REACT_APP_BACKEND;

export const calculateProgress = (plan: Plan): number =>
	segments
		.map((segment) =>
			plan.segmentStatuses.find(
				(x) =>
					x.segmentGroup === segment.segmentGroup &&
					x.segment === segment.segment
			)
		)
		.filter((x) => x?.status === Status.Done).length / segments.length;

export const setStatus = (
	setPlan: StateUpdater<Plan>,
	segmentGroup: SegmentGroup,
	segment: Segment,
	status?: Status
) =>
	setPlan((plan) => {
		const authenticatedFetch = useAuthenticatedFetch();

		const currentStatus = plan.segmentStatuses.find(
			(x) => x.segmentGroup === segmentGroup && x.segment === segment
		)?.status;
		status = status ?? currentStatus ?? Status.Waiting;
		if ((currentStatus && !status) || currentStatus === status) return plan;
		const newStatus = { planId: plan.planId, segmentGroup, segment, status };
		authenticatedFetch(`${API_URL}/plan/${plan.planId}/status`, {
			method: 'PUT',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify(newStatus),
		});
		return {
			...plan,
			segmentStatuses: plan.segmentStatuses
				.filter(
					(x) => x.segmentGroup !== segmentGroup || x.segment !== segment
				)
				.concat([newStatus]),
		};
	});

const StyledHr = styled.hr`
	border: var(--border-light-grey);
	margin-top: 2em;
	margin-bottom: 1em;
`;

const StatusCheckBox: FC<{
	plan: Plan;
	setPlan: React.Dispatch<React.SetStateAction<Plan>>;
	segmentGroup: SegmentGroup;
	segment: Segment;
}> = ({ plan, setPlan, segmentGroup, segment }) => (
	<>
		<StyledHr />
		<AppCheckbox
			inputSize={Size.Large}
			checked={
				plan.segmentStatuses.find(
					(x) => x.segmentGroup === segmentGroup && x.segment === segment
				)?.status === Status.Done
			}
			onCheck={(checked) =>
				setStatus(
					setPlan,
					segmentGroup,
					segment,
					checked ? Status.Done : Status.Waiting
				)
			}
		>
			Informatie toegepast op participatieplan
		</AppCheckbox>
	</>
);

export const planStatus = (segmentStatuses: SegmentStatus[]) =>
	segmentGroups.find(
		(x) =>
			x.segmentGroup ===
			[
				...segmentStatuses,
				{
					segmentGroup: SegmentGroup.Initiative,
					status: Status.NoStatus,
				},
			].find((x) => x.status != Status.Done)?.segmentGroup
	)?.title ?? 'Finished';

export default StatusCheckBox;
