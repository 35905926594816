import { FC } from 'react';
import styles from './ProgressBar.module.css';

const ProgressBar: FC<{ progress: number; showPercentage?: boolean }> = ({
	progress,
	showPercentage = false,
}) => (
	<div className={styles.ProgressBar}>
		<div
			className={styles.ProgressBarFiller}
			style={{ width: `${progress * 100}%` }}
		>
			{showPercentage && (progress ? <span>{`${progress * 100}%`}</span> : '')}
		</div>
	</div>
);

export default ProgressBar;
