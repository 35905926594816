import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Narrow a setState hook to a property
 * @param setState The setState hook for the full object/array
 * @param key The key of the child property/element
 * @returns A setState hook which only updates state[key]
 */
const slice =
	<T, U extends keyof T>(
		setState: Dispatch<SetStateAction<T>>,
		key: U
	): Dispatch<SetStateAction<T[U]>> =>
	(newState) =>
		setState((state) =>
			Object.assign(Array.isArray(state) ? [] : {}, state, {
				[key]: newState instanceof Function ? newState(state[key]) : newState,
			})
		);

export default slice;

export type WithSetter<Props> = Props & {
	[k in keyof Props as `set${Capitalize<string & k>}`]: Dispatch<
		SetStateAction<Props[k]>
	>;
};

export const useStorage = <T>(
	key: string,
	initialValue: T,
	storage: Storage = localStorage
): [T, Dispatch<SetStateAction<T>>] => {
	const parseValue = (value: string | null) =>
		value && value !== 'undefined' ? JSON.parse(value) : initialValue;
	const [value, setValue] = useState(() => parseValue(storage.getItem(key)));
	self.addEventListener(
		'storage',
		(e) => e.key === key && setValue(parseValue(e.newValue))
	);
	useEffect(
		() => storage.setItem(key, JSON.stringify(value)),
		[key, value, storage]
	);
	return [value, setValue];
};

export type StateUpdater<T> = React.Dispatch<React.SetStateAction<T>>;

export interface SlicedElement<T> {
	value: T;
	setValue: StateUpdater<T>;
	remove: () => void;
	prepend: (newValue: T) => void;
	append: (newValue: T) => void;
}

export const mapSliced = <T, U>(
	array: T[],
	setArray: StateUpdater<T[]>,
	mapper: (props: SlicedElement<T>, index: number) => U
): U[] =>
	array
		.map((x, i) => ({
			value: x,
			setValue: slice(setArray, i),
			remove: () =>
				setArray((array) => {
					const i = array.indexOf(x);
					return array.slice(0, i).concat(array.slice(i + 1));
				}),
			prepend: (newValue: T) =>
				setArray((array) => {
					const i = array.indexOf(x);
					return array.slice(0, i).concat([newValue]).concat(array.slice(i));
				}),
			append: (newValue: T) =>
				setArray((array) => {
					const i = array.indexOf(x);
					return array
						.slice(0, i + 1)
						.concat([newValue])
						.concat(array.slice(i + 1));
				}),
		}))
		.map(mapper);
