import { FC } from 'react';
import * as React from 'react';
import styles from './AppIcon.module.css';
import { Size } from '../../types';

const AppIcon: FC<{
	icon: React.ReactNode;
	size?: Size;
	iconRight?: boolean;
	spaceBetween?: boolean;
	children?: React.ReactNode;
}> = ({ icon, size, iconRight = false, spaceBetween = false, children }) => (
	<i
		className={`${styles.AppIcon} ${
			iconRight ? styles.AppIconRight : styles.AppIconLeft
		} ${size ? styles['AppIcon' + size] : ''} ${
			spaceBetween ? styles.AppIconSpaceBetween : ''
		}`}
		role="presentation"
	>
		{!iconRight && icon}
		{children}
		{iconRight && icon}
	</i>
);

export default AppIcon;
