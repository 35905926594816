import React, { FC, useContext, useEffect, useState } from 'react';
import styles from './SegmentView.module.css';
import TextBox from './TextBox/TextBox';
import Text from './Text/Text';
import { Link, useParams } from 'react-router-dom';
import { Plan, Size, SegmentGroup, Segment } from '../../types';
import { PlanContext, PlanContextProps } from '../../contexts/PlanContext';
import SideBar from '../../components/SideBar/SideBar';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import AppButton from '../../components/Button/AppButton';
import AppIcon from '../../components/AppIcon/AppIcon';
import Icon from '@mdi/react';
import { ReactComponent as MessageIcon } from '../../icons/message.svg';
import { mdiChevronLeft, mdiChevronRight, mdiLink } from '@mdi/js';
import { segments } from '../../segments';
import SidePanel from '../../components/SidePanel/SidePanel';
import { setStatus } from '../../components/Status/Status';
import Roadmap from '../../components/Roadmap/Roadmap';
import { useAuthenticatedFetch } from '../../auth';
import styled from 'styled-components';

export type hyperlink = {
	url: string;
	text: string;
};

export const StyledContent = styled.section`
	a {
		text-decoration: underline;
	}

	a:hover {
		text-decoration: none;
	}
`;

export const InfoBar: FC<{
	paragraphs?: string[];
	hyperlinks?: hyperlink[];
	imageTitle?: string;
	image?: string;
}> = ({ paragraphs, hyperlinks, imageTitle, image }) => (
	<div className={styles.InfoBar}>
		<AppIcon icon={<MessageIcon title="commentbox" />} />
		<h2>Relevante Informatie</h2>
		<StyledContent>
			{paragraphs?.map((x, i) => (
				<p key={i} dangerouslySetInnerHTML={{ __html: x }}></p>
			))}
		</StyledContent>

		<div className={styles.InfoBarLinksContainer}>
			{hyperlinks?.map((link, index) => (
				<div key={index} className={styles.InfoBarLink}>
					<AppIcon icon={<Icon path={mdiLink} size={1} />}>
						<a href={link.url} target={'_blank'} rel={'noreferrer'}>
							{link.text}
						</a>
					</AppIcon>
				</div>
			))}
		</div>

		{image && (
			<div className={styles.InfoBarImage}>
				<PopupImage altText={imageTitle ?? ''} src={image} />
			</div>
		)}
	</div>
);

export const PopupImage: FC<{
	src: string;
	altText: string;
}> = ({ src, altText, ...props }) => {
	const [open, setOpen] = useState(false);

	return (
		<div {...props}>
			<img
				src={src}
				alt={altText}
				title={altText}
				onClick={() => setOpen(true)}
			/>
			<SidePanel title={altText} open={open} setOpen={setOpen} wide={true}>
				<div className={styles.InfoBarImagePopup}>
					<img src={src} alt={altText} title={altText} />
				</div>
			</SidePanel>
		</div>
	);
};

const SegmentView: FC<unknown> = () => {
	const { plan, setPlan, setPlanFromBackend } =
		useContext<PlanContextProps>(PlanContext);
	const { segmentGroup, segment } = useParams<{
		segmentGroup: SegmentGroup;
		segment: Segment;
	}>();
	const template = segments.find(
		(x) => x.segmentGroup === segmentGroup && x.segment === segment
	);
	const planId = plan?.planId;
	const authenticatedFetch = useAuthenticatedFetch();
	useEffect(() => {
		if (!planId || !segmentGroup || !segment) return;
		authenticatedFetch(`${process.env.REACT_APP_BACKEND}/plan/${planId}`)
			.then((response) => response.json())
			.then((plan: Plan) => {
				setPlanFromBackend(plan);
				setStatus(setPlan, segmentGroup, segment);
			})
			.catch((e) => console.log(e));
	}, [
		planId,
		setPlan,
		setPlanFromBackend,
		segmentGroup,
		segment,
		authenticatedFetch,
	]);
	if (!template) return null;

	return (
		<>
			<SideBar activeSegmentGroup={segmentGroup} activeSegment={segment} />
			{plan && segmentGroup && segment && (
				<>
					<div className={styles.SegmentPageWrapper}>
						<div className={styles.Header}>
							<Breadcrumb
								activeSegmentGroup={segmentGroup}
								activeSegment={segment}
							/>
							<Roadmap
								plan={plan}
								activeSegmentGroup={segmentGroup}
								activeSegment={segment}
							/>
						</div>
						<div className={template.info ? styles.InfoGrid : ''}>
							{template.type === 'none' ? (
								'No page yet'
							) : template.type === 'component' ? (
								React.createElement(template.component, {
									plan,
									setPlan,
									template,
								})
							) : template.type === 'text' ? (
								<Text template={template} plan={plan} setPlan={setPlan} />
							) : (
								<TextBox plan={plan} setPlan={setPlan} template={template} />
							)}
							{template.info ? (
								<InfoBar
									paragraphs={template.info.paragraphs}
									image={template.info.image}
									imageTitle={template.info.imageTitle}
									hyperlinks={template.info.hyperlinks}
								/>
							) : null}
						</div>
					</div>
					<StepsNavigationBar
						plan={plan}
						segmentGroup={segmentGroup}
						segment={segment}
					/>
				</>
			)}
		</>
	);
};

const StepsNavigationBar: FC<{
	plan: Plan;
	segmentGroup: SegmentGroup;
	segment: Segment;
}> = ({ plan, segmentGroup, segment }) => {
	const index = segments.findIndex(
		(x) => x.segmentGroup === segmentGroup && x.segment === segment
	);
	const toLink = (
		segment: (typeof segments)[number] | undefined
	): string | undefined =>
		segment
			? `/plan/${plan.planId}/segment/${segment.segmentGroup}/${segment.segment}`
			: undefined;

	return (
		<div className={styles.SegmentNavigationBar}>
			<div className={styles.SegmentNavigationBarButtons}>
				<Link
					to={toLink(segments[index - 1]) ?? ''}
					className={
						segments[index - 1] ? styles.SegmentNavigationBarLinkDisabled : ''
					}
				>
					<AppButton disabled={segments[index - 1] === undefined} noMargin>
						<AppIcon
							icon={<Icon path={mdiChevronLeft} size={1} />}
							size={Size.ExtraSmall}
						>
							VORIGE STAP
						</AppIcon>
					</AppButton>
				</Link>
				<Link
					to={toLink(segments[index + 1]) ?? ''}
					className={
						segments[index + 1] ? styles.SegmentNavigationBarLinkDisabled : ''
					}
				>
					<AppButton disabled={segments[index + 1] === undefined} noMargin>
						<AppIcon
							icon={<Icon path={mdiChevronRight} size={1} />}
							size={Size.ExtraSmall}
							iconRight={true}
						>
							VOLGENDE STAP
						</AppIcon>
					</AppButton>
				</Link>
			</div>
		</div>
	);
};

export default SegmentView;
