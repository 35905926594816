import { FC, useEffect, useRef, useState } from 'react';
import styles from './AppDropdown.module.css';
import { Size } from '../../types';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from '@mdi/react';

const AppDropdown: FC<{
	label?: string;
	onSelect?: (selected: string) => void;
	inputSize?: Size;
	defaultItem?: string;
	items: { name: string; value: string }[];
}> = ({ label, defaultItem, onSelect, items }) => {
	const [open, setOpen] = useState(false);

	const [selectedItem, selectItem] = useState<string>();

	const wrapperRef = useRef<HTMLDivElement>(null);
	useOutsideAlerter(wrapperRef, setOpen, open);

	useEffect(() => {
		selectItem(defaultItem);
	}, [defaultItem]);

	return (
		<div className={styles.DropdownContainer} ref={wrapperRef}>
			{label && (
				<label htmlFor={'AppDropdown'} className={styles.AppInputLabel}>
					{label}
				</label>
			)}
			<button
				type={'button'}
				className={styles.DropdownButton}
				onClick={() => setOpen(!open)}
			>
				{selectedItem
					? items.find((x) => x.value == selectedItem)?.name
					: 'Selecteer een optie'}
				<Icon path={open ? mdiChevronUp : mdiChevronDown} size={1} />
			</button>
			<ul
				tabIndex={-1}
				role={'listbox'}
				className={`${styles.SelectList} ${!open ? styles.Hidden : ''}`}
			>
				{items &&
					items.map((item, i) => (
						<li
							key={i}
							className={
								(selectedItem == item.value ? styles.Focused : '') +
								' ' +
								styles.SelectList
							}
							onClick={() => {
								selectItem(item.value);
								setOpen(false);
								onSelect?.(item.value);
							}}
							role={'option'}
						>
							{item.name}
						</li>
					))}
			</ul>
		</div>
	);
};

function useOutsideAlerter(
	ref: React.RefObject<HTMLElement>,
	setOpen: React.Dispatch<React.SetStateAction<boolean>>,
	open: boolean
) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			if (open && ref.current && !ref.current.contains(event.target as Node)) {
				setOpen(false);
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, open, setOpen]);
}

export type SelectItem = {
	value: string;
	id: string;
};

export default AppDropdown;
