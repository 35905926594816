import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SegmentGroup, Segment } from '../../types';

const StepLink: FC<{
	planId: number;
	segmentGroup: SegmentGroup;
	segment: Segment;
	title: string;
	onFocus?: () => void;
}> = ({ planId, segmentGroup, segment, title, onFocus }) => (
	<Link
		onFocus={onFocus}
		to={`/plan/${planId}/segment/${segmentGroup}/${segment}`}
	>
		{title}
	</Link>
);

export default StepLink;
