import { FC, RefAttributes, useEffect, useState } from 'react';
import { Link, LinkProps } from 'react-router-dom';

const DownloadLink: FC<LinkProps & RefAttributes<HTMLAnchorElement>> = ({
	to,
	children,
	...props
}) => {
	const [formattedFileSize, setFormattedFileSize] = useState('');

	useEffect(() => {
		fetch(to.toString()).then((resp) => {
			const fileSize = resp.headers.get('Content-Length');
			if (fileSize) {
				const formattedFileSize = formatBytes(Number(fileSize));
				setFormattedFileSize(formattedFileSize);
			}
		});
	}, [to]);

	function formatBytes(bytes: number, decimals = 2) {
		const bytesInMB = 1000 * 1000;

		const sizeInMb = bytes / bytesInMB;
		const sizes = ['MB', 'GB'];

		const formattedSize = parseFloat(sizeInMb.toFixed(decimals));
		const unit = sizes[Math.floor(sizeInMb / 1000)];

		return `${formattedSize} ${unit}`;
	}

	return (
		<span>
			<Link to={to} {...props}>
				{children}
			</Link>{' '}
			{formattedFileSize && `(${formattedFileSize})`}
		</span>
	);
};

export default DownloadLink;
