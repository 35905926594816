import { FC, useCallback, useEffect } from 'react';
import styles from './SidePanel.module.css';
import { mdiCloseBox } from '@mdi/js';
import Icon from '@mdi/react';

const SidePanel: FC<{
	title: string;
	open: boolean;
	setOpen: (open: boolean) => void;
	wide?: boolean;
	children: React.ReactNode;
}> = ({ title, open, setOpen, wide, children }) => {
	const handleKeydown = useCallback(
		(e: KeyboardEvent) => {
			if (e.key !== 'Escape') return;
			setOpen(false);
		},
		[setOpen]
	);

	useEffect(() => {
		if (open) {
			document.addEventListener('keydown', handleKeydown, false);
		} else {
			document.removeEventListener('keydown', handleKeydown, false);
		}
	}, [open, handleKeydown]);

	return (
		<>
			<div
				className={`${styles.SidePanelPageMask} ${
					open ? styles.SidePanelPageMaskActive : ''
				}`}
				onClick={() => {
					setOpen(false);
				}}
			/>
			<div
				className={`${styles.SidePanel} ${open ? styles.SidePanelOpen : ''} ${
					wide ? styles.SidePanelWide : ''
				}`}
			>
				{open && (
					<>
						<div className={styles.SidePanelHeader}>
							<h2 className="h1-style">{title}</h2>
							<div
								className={styles.SidePanelCloseButton}
								onClick={() => {
									setOpen(false);
								}}
							>
								<Icon path={mdiCloseBox} size={1.4} />
							</div>
						</div>
						<div className={styles.SidePanelContent}>{children}</div>
					</>
				)}
			</div>
		</>
	);
};

export default SidePanel;
