import { FC, InputHTMLAttributes, TextareaHTMLAttributes } from 'react';
import styles from './AppInput.module.css';

const AppInput: FC<
	{
		label: string;
	} & InputHTMLAttributes<HTMLInputElement>
> = ({ label, ...props }) => (
	<div className={styles.AppInputContainer}>
		<label htmlFor={'appInput'} className={styles.AppInputLabel}>
			{`${label}${props.required ? ' *' : ''}`}
		</label>
		<input
			type={'text'}
			name={'appInput'}
			className={styles.AppInput}
			{...props}
		/>
	</div>
);
export const AppTextarea: FC<
	{
		label: string;
	} & TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({ label, ...props }) => (
	<div className={styles.AppInputContainer}>
		<label htmlFor={'appInput'} className={styles.AppInputLabel}>
			{`${label}${props.required ? ' *' : ''}`}
		</label>
		<textarea name={'appInput'} className={styles.AppInput} {...props} />
	</div>
);

export default AppInput;
