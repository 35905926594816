import { FC } from 'react';
import styled from 'styled-components';
import { mdiExport } from '@mdi/js';
import Icon from '@mdi/react';

export const StyledLinkButton = styled.a`
	display: inline-block;
	margin-right: 1rem;
	padding: 0.8rem 1.2rem;
	background-color: var(--color-accent-teal-button);
	color: var(--color-accent-white);
	font-size: unset;
	font-family: var(--font-accent);
	font-weight: bold;
	border-radius: 3px;
	box-shadow:
		rgb(0 0 0 / 16%) 0 3px 6px,
		rgb(0 0 0 / 23%) 0 3px 6px;
	border-style: none;
	transition:
		filter 300ms,
		box-shadow 200ms ease-in-out;
`;

const LinkButton: FC<{
	filePath: string;
	fileName: string;
	key: string;
	title: string;
}> = ({ filePath, fileName, key, title }) => (
	<StyledLinkButton
		href={process.env.PUBLIC_URL + filePath + fileName}
		download={fileName}
		rel="noreferrer"
		key={key}
	>
		{title}
		<Icon path={mdiExport} size={0} />
	</StyledLinkButton>
);

export default LinkButton;
