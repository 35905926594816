import React, { useState, useContext, Dispatch, useEffect } from 'react';

type Dragged = { type: string; value: unknown } | undefined;

const DraggedContext = React.createContext({
	dragged: undefined as Dragged,
	setDragged: undefined as unknown as Dispatch<Dragged>,
	onDragEnd: undefined as unknown as (success: boolean) => void,
	setOnDragEnd: undefined as unknown as (
		onDragEnd: (success: boolean) => void
	) => void,
});

/** Wraps all components that use drag and drop with a Context */
export const DragDropProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [dragged, setDragged] = useState(undefined as Dragged);
	const [onDragEnd, setOnDragEnd] = useState(
		() => undefined as unknown as (success: boolean) => void
	);
	return (
		<DraggedContext.Provider
			value={{ dragged, setDragged, onDragEnd, setOnDragEnd }}
		>
			{children}
		</DraggedContext.Provider>
	);
};

/**
 * Defines properties for a component that can be dragged
 * @param props.type A string to identify the type of the value
 * @param props.value The dragged value
 * @param props.onDragEnd Function to call when this value stops being dragged
 * @param props.dropEffect The type of drag operation
 * @returns Properties to make a component draggable, including data-dragging,
 * which is true if this component is being dragged
 */
export const useDragSource = <T,>({
	type,
	value,
	onDragEnd = () => undefined,
	dropEffect,
}: {
	type: string;
	value: unknown;
	onDragEnd?: (succes: boolean) => void;
	dropEffect: 'link' | 'none' | 'copy' | 'move';
}): React.HTMLAttributes<T> => {
	const { dragged, setDragged, setOnDragEnd } = useContext(DraggedContext);
	return {
		...{ 'data-dragging': dragged?.value === value },
		draggable: true,
		onDragStart: (ev) => {
			ev.stopPropagation();
			ev.dataTransfer.dropEffect = dropEffect;
			ev.dataTransfer.setData('text/plain', type); //only to allow the drag
			// setTimeout workaround for chrome drag bug, onDragEnd is called instantly after updating the dom
			// https://stackoverflow.com/questions/28408720/jquery-changing-the-dom-on-dragstart-event-fires-dragend-immediately
			setTimeout(() => setDragged({ type, value }), 0);
			setOnDragEnd(() => onDragEnd);
			return false;
		},

		onDragEnd: (ev) => {
			ev.stopPropagation();
			ev.preventDefault();
			if (ev.dataTransfer.dropEffect === 'none') {
				onDragEnd(false);
			}
			setDragged(undefined);
			return false;
		},
	};
};

/**
 * Defines properties for a drop zone
 * @param props.typesAllowed Types (from useDragSource) that are allowed here.
 * @param props.onDrop Function to call when a value is dropped here
 * @param props.value The value that's already here
 * @returns Properties to make a component a drop zone, including
 * data-hovering, which contains the type if it is allowed and currently
 * hovering over the component
 */
export const useDragSink = <T,>({
	typesAllowed,
	onDrop,
	value,
}: {
	typesAllowed: string[];
	onDrop: (value: unknown, type: string) => void;
	value: unknown;
}): React.HTMLAttributes<T> => {
	const {
		dragged,
		setDragged,
		onDragEnd: sourceOnDragEnd,
	} = useContext(DraggedContext);
	const [hoverType, setHoverType] = useState<string | null>(null);
	useEffect(() => {
		if (dragged?.type !== hoverType) setHoverType(null);
	}, [dragged?.type, hoverType]);
	return {
		...{
			'data-hovering':
				hoverType && typesAllowed.includes(hoverType) ? hoverType : undefined,
		},
		onDrop: (ev) => {
			if (dragged && typesAllowed.includes(dragged.type)) {
				ev.preventDefault();
				ev.stopPropagation();
				setHoverType(null);
				if (value !== dragged.value) {
					sourceOnDragEnd(true);
					onDrop(dragged.value, dragged.type);
				} else {
					sourceOnDragEnd(false);
				}
				setDragged(undefined);
			}
		},
		onDragOver: (ev) => {
			if (
				dragged &&
				typesAllowed.includes(dragged.type) &&
				value !== dragged.value
			) {
				ev.preventDefault();
				ev.stopPropagation();
				setHoverType(dragged.type);
				ev.dataTransfer.effectAllowed = 'all';
			}
		},
		onDragEnter: (ev) => {
			if (
				dragged &&
				typesAllowed.includes(dragged.type) &&
				value !== dragged.value
			) {
				ev.preventDefault();
				ev.stopPropagation();
				setHoverType(dragged.type);
				ev.dataTransfer.effectAllowed = 'all';
			}
		},
		onDragLeave: (ev) => {
			ev.preventDefault();
			ev.stopPropagation();
			setHoverType(null);
		},
	};
};
