import styled from 'styled-components';
import LinkButton from '../../../components/LinkButton/LinkButton';
import StatusCheckBox from '../../../components/Status/Status';
import { SegmentTemplate } from '../../../segments';
import { Plan } from '../../../types';

export const StyledContent = styled.div`
	ul {
		list-style-type: disc;
		padding-left: 3rem;
	}

	a {
		text-decoration: underline;
	}

	a:hover {
		text-decoration: none;
	}
`;

const Text: React.FC<{
	template: SegmentTemplate;
	plan: Plan;
	setPlan: React.Dispatch<React.SetStateAction<Plan>>;
}> = ({ plan, setPlan, template }) =>
	template.type === 'text' ? (
		<div>
			<h2 className="h1-style">{template.title}</h2>
			<StyledContent
				dangerouslySetInnerHTML={{ __html: template.content }}
			></StyledContent>
			{template.buttons?.map((link) => (
				<LinkButton
					title={link.title}
					fileName={link.filePath}
					filePath={link.filePath}
					key={link.key}
				></LinkButton>
			))}
			<StatusCheckBox
				segmentGroup={template.segmentGroup}
				segment={template.segment}
				plan={plan}
				setPlan={setPlan}
			/>
		</div>
	) : null;

export default Text;
