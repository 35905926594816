import { createContext, useContext, useEffect, useState } from 'react';
import { StateUpdater } from './StateManagement';
/*eslint react-hooks/exhaustive-deps:0*/

const configureRefreshFetch =
	({
		refreshToken,
		shouldRefreshToken,
		fetch,
	}: {
		refreshToken: () => Promise<void>;
		shouldRefreshToken: (error: Response) => Promise<boolean>;
		fetch: typeof self.fetch;
	}): typeof fetch =>
	(input, init) =>
		fetch(input, init).then((x) =>
			shouldRefreshToken(x).then((y) =>
				y ? refreshToken().then(() => fetch(input, init)) : x
			)
		);

interface AuthorizeParams {
	client_id: string;
	redirect_uri: string;
	scope: string;
	prompt?: 'none';
}

const loginUri = (
	endpoint: string,
	params: AuthorizeParams,
	setNonce: StateUpdater<string | null>
) => {
	const newNonce = Array.from(
		self.crypto.getRandomValues(new Uint8Array(16)),
		(x) => x.toString(16).padStart(2, '0')
	).join('');
	setNonce(newNonce);
	return `${endpoint}?${new URLSearchParams({
		response_type: 'id_token',
		...params,
		nonce: newNonce,
	})}`;
};

const urlPath = (url: string) =>
	new URL(url ?? '', self.location.origin).pathname;

export const useOidcImplicitToken = (
	endpoint: string,
	params: AuthorizeParams,
	{
		navigate,
		testClaims,
		redirectUri,
		setRedirectUri,
		nonce,
		setNonce,
	}: {
		navigate: (to: string) => void;
		testClaims?: undefined | (() => Record<string, string | number>);
		redirectUri: string | null;
		setRedirectUri: StateUpdater<string | null>;
		nonce: string | null;
		setNonce: StateUpdater<string | null>;
	}
) => {
	const [token, setToken] = useState<string | null>(null);
	useEffect(() => {
		const cleanState = () => {
			setNonce(null);
			setRedirectUri(null);
		};
		if (token) return cleanState();
		if (testClaims) return setToken(generateJWT(testClaims()));
		if (self.location.pathname === urlPath(params.redirect_uri)) {
			const newToken = new URLSearchParams(
				self.location.hash.replace(/^#/, '')
			).get('id_token');
			if (newToken === null || parseJWT(newToken).nonce !== nonce)
				return cleanState();
			if (self.frameElement)
				window.parent.postMessage(newToken, self.location.origin);
			else setToken(newToken);
			cleanState();

			navigate(
				redirectUri && urlPath(params.redirect_uri) !== urlPath(redirectUri)
					? redirectUri
					: '/'
			);
		} else if (endpoint) {
			setRedirectUri(
				self.location.toString().replace(self.location.origin, '')
			);
			window.location.assign(loginUri(endpoint, params, setNonce));
		} else {
			console.error('OIDC endpoint not set');
		}
	}, [
		token,
		endpoint,
		params.redirect_uri,
		navigate,
		testClaims,
		redirectUri,
		setNonce,
		setRedirectUri,
	]);
	return {
		token,
		fetch: configureRefreshFetch({
			fetch: (resource, options) =>
				fetch(resource, {
					...options,
					headers: { ...options?.headers, Authorization: `Bearer ${token}` },
				}),
			shouldRefreshToken: (response) =>
				Promise.resolve(
					response.status === 401 &&
						response.headers.get('WWW-Authenticate')?.match(/expired/) !== null
				),
			refreshToken: () => {
				if (testClaims)
					return Promise.resolve(setToken(generateJWT(testClaims())));
				const iframe = document.createElement('iframe');
				iframe.setAttribute(
					'src',
					loginUri(endpoint, { ...params, prompt: 'none' }, setNonce)
				);
				iframe.style.display = 'none';
				document.body.appendChild(iframe);

				const onMessage = (event: MessageEvent) => {
					if (
						event.origin !== window.location.origin ||
						iframe.contentWindow !== event.source ||
						parseJWT(event.data).nonce !== nonce
					)
						return Promise.reject();
					setToken(event.data);
					setNonce(null);
					document.body.removeChild(iframe);
					window.removeEventListener('message', onMessage);
				};

				window.addEventListener('message', onMessage);
				return Promise.resolve();
			},
		}),
	};
};

export const TokenContext = createContext(
	undefined as unknown as {
		token: string | null;
		fetch: (resource: RequestInfo, options?: RequestInit) => Promise<Response>;
	}
);

export const parseJWT = (token: string) =>
	JSON.parse(
		decodeURIComponent(
			self
				.atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'))
				.split('')
				.map((x) => '%' + ('00' + x.charCodeAt(0).toString(16)).slice(-2))
				.join('')
		)
	);

export const generateExpiry = () => ({
	iat: Math.floor(new Date().getTime() / 1000),
	nbf: Math.floor(new Date().getTime() / 1000),
	exp: Math.floor(
		new Date(new Date().getTime() + 15 * 60 * 1000).getTime() / 1000
	),
});

const base64url = (source: string) =>
	self.btoa(source).replace(/=+$/, '').replace(/\+/g, '-').replace(/\//g, '_');

const generateJWT = (claims: Record<string, string | number>) =>
	`${base64url(
		JSON.stringify({
			alg: 'none',
			typ: 'JWT',
		})
	)}.${base64url(JSON.stringify(claims))}.`;

export const useAuthenticatedFetch = () => useContext(TokenContext).fetch;
