import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
	Step,
	Plan,
	Size,
	SubStep,
	Segment,
	SegmentGroup,
	Status,
	StakeholderGroup,
	MandateTranslations,
} from '../../../types';
import AppIcon from '../../../components/AppIcon/AppIcon';
import AppButton from '../../../components/Button/AppButton';
import AppInput from '../../../components/AppInput/AppInput';
import AppCheckbox from '../../../components/AppCheckbox/AppCheckbox';
import SidePanel from '../../../components/SidePanel/SidePanel';
import Icon from '@mdi/react';
import {
	mdiBookmarkPlus,
	mdiArrowAll,
	mdiPlusBox,
	mdiCog,
	mdiDelete,
	mdiFlagVariant,
	mdiContentSave,
} from '@mdi/js';
import Accordion from '../../../components/Accordion/Accordion';
import slice, {
	WithSetter,
	SlicedElement,
	mapSliced,
	StateUpdater,
} from '../../../StateManagement';
import { SegmentTemplate } from '../../../segments';
import { setStatus } from '../../../components/Status/Status';
import {
	DeleteSubStepButton,
	UpsertSubstepButton,
} from './Substep/SubstepForm';
import {
	DragDropProvider,
	useDragSink,
	useDragSource,
} from '../../../components/DragDrop/DragDrop';
import {
	ButtonsContainer,
	StepContainer,
	SegmentTitle,
	StepTitle,
	StyledSubStepTitle,
	StyledStakeholderTable,
} from './ParticipationProcessStyle';
import { useAuthenticatedFetch } from '../../../auth';
import DownloadLink from '../../../components/DownloadLink/DownloadLink';

const API_URL = process.env.REACT_APP_BACKEND;

const DefineParticipationPlan: FC<{
	plan: Plan;
	setPlan: Dispatch<SetStateAction<Plan>>;
	template: SegmentTemplate;
}> = ({ plan, setPlan, template }) => {
	const [ordering, setOrdering] = useState(false);
	const [open, setOpen] = useState(true);
	const [openAccordion, setOpenAccordion] = useState<number>(0);
	const authenticatedFetch = useAuthenticatedFetch();
	useEffect(() => {
		setOpenAccordion(openAccordion);
	}, [openAccordion]);
	useEffect(
		() =>
			ordering
				? serializeOrder(
						plan.steps,
						slice(setPlan, 'steps'),
						plan.planId,
						undefined,
						authenticatedFetch
					)
				: undefined,
		[plan.steps] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const addStep = (newValue: Step) => {
		setStatus(
			setPlan,
			SegmentGroup.Initiative,
			Segment.ParticipationProcess,
			Status.Done
		);
		return slice(setPlan, 'steps')([...plan.steps, newValue]);
	};

	return (
		<>
			<SegmentTitle as={'div'}>
				<h2 className="h1-style" style={{ marginBottom: 0 }}>
					{template.title}
				</h2>
				<AppIcon icon={<Icon path={mdiFlagVariant} size={1} />}>
					Milestone
				</AppIcon>
			</SegmentTitle>
			<Accordion
				title={'Meer informatie'}
				id={0}
				isOpen={open && openAccordion == 0}
				setOpen={setOpen}
				setActiveItem={setOpenAccordion}
			>
				<p>
					Met de informatie en inzichten uit de voorgaande stappen kan nu het
					participatieproces opgesteld worden. Dit doe je, met bijvoorbeeld een
					projectplanning als onderlegger, op de volgende manier:
				</p>
				<ol>
					<li>
						Bepaal de de hoofdfasen van het initiatief en maak deze aan met de
						knop hieronder.
					</li>
					<li>
						Bepaal per fase de substappen die erin doorlopen worden en maak
						deze aan. Substappen zijn er tenminste voor alle belangrijke
						participatiestappen in het proces. Iedere substap heeft een eigen
						concreet en afgebakend doel.
					</li>
					<li>
						Vul per substap in: Waar het over gaat. In welke periode het zich
						afspeelt. En, wie er, binnen welk domein en met welk mandaat bij
						betrokken zijn.
					</li>
					<li>
						Neem tenminste per fase ook een meting e/o evaluatie op, zodat je
						tijdig nagaat of iedereen nog het juiste doet. Stuur bij indien
						nodig.
					</li>
				</ol>
				<p>
					Twee voorbeelden van een ingevuld participatieproces kun je hier{' '}
					<DownloadLink
						target="_blank"
						download={'participatieproces_Merlyn.pdf'}
						rel="noreferrer"
						to={
							process.env.PUBLIC_URL +
							'/downloads/participatieproces_Merlyn.pdf'
						}
					>
						participatieproces_Merlyn
					</DownloadLink>{' '}
					en hier{' '}
					<DownloadLink
						target="_blank"
						download={'Participatieproces_Woonwagenstandplaatsen.pdf'}
						rel="noreferrer"
						to={
							process.env.PUBLIC_URL +
							'/downloads/Participatieproces_Woonwagenstandplaatsen.pdf'
						}
					>
						Participatieproces Woonwagenstandplaatsen
					</DownloadLink>{' '}
					zien.
				</p>
			</Accordion>
			<ButtonsContainer>
				<AddStepButton planId={plan.planId} addStep={addStep} />
				<OrderStepsButton ordering={ordering} setOrdering={setOrdering} />
			</ButtonsContainer>

			<DragDropProvider>
				{mapSliced(
					plan.steps,
					slice(setPlan, 'steps'),
					(props, index: number) => (
						<StepView
							key={props.value.stepId}
							plan={plan}
							index={index + 1}
							ordering={ordering}
							{...props}
						/>
					)
				)}
			</DragDropProvider>

			{plan.steps.length > 3 && (
				<ButtonsContainer>
					<AddStepButton planId={plan.planId} addStep={addStep} />
					<OrderStepsButton ordering={ordering} setOrdering={setOrdering} />
				</ButtonsContainer>
			)}
		</>
	);
};

const serializeOrder = <
	T extends Step | SubStep,
	U extends T extends Step ? undefined : number,
>(
	list: T[],
	listUpdater: StateUpdater<T[]>,
	planId: number,
	stepId: U,
	fetch: ReturnType<typeof useAuthenticatedFetch>
) =>
	list.some((x, i) => x.order !== i || ('stepId' in x && x.stepId !== stepId))
		? listUpdater((list) =>
				list.map((x, i) => {
					if (i === x.order || ('stepId' in x && x.stepId === stepId))
						return x;
					const updated = { ...x, order: i, stepId };
					fetch(
						`${API_URL}/plan/${planId}/step` +
							('subStepId' in updated ? `/${stepId}/subStep` : ''),
						{
							method: 'PUT',
							headers: { 'Content-type': 'application/json' },
							body: JSON.stringify(updated),
						}
					);
					return updated;
				})
			)
		: undefined;

const OrderStepsButton: FC<{
	ordering: boolean;
	setOrdering: StateUpdater<boolean>;
}> = ({ ordering, setOrdering }) => {
	return (
		<AppButton white={!ordering} onClick={() => setOrdering((x) => !x)}>
			<AppIcon icon={<Icon path={mdiArrowAll} size={1} />} iconRight>
				{!ordering ? 'ORDENEN' : 'STOP ORDENEN'}
			</AppIcon>
		</AppButton>
	);
};

const StepView = ({
	index,
	plan,
	value,
	setValue,
	prepend,
	append,
	remove,
	ordering,
}: { index: number; plan: Plan; ordering: boolean } & SlicedElement<Step>) => {
	const authenticatedFetch = useAuthenticatedFetch();
	useEffect(
		() =>
			ordering
				? serializeOrder(
						value.subSteps,
						slice(setValue, 'subSteps'),
						plan.planId,
						value.stepId,
						authenticatedFetch
					)
				: undefined,
		[plan.steps] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const dragSource = useDragSource({
		type: 'step',
		value,
		onDragEnd: (success) => success && remove(),
		dropEffect: 'move',
	});
	return (
		<StepContainer>
			<header
				{...useDragSink({
					typesAllowed: ['step', 'subStep'],
					value,
					onDrop: (value, type) => {
						if (type === 'step') prepend(value as Step);
						else if (type === 'subStep')
							setValue((step) => ({
								...step,
								subSteps: [value as SubStep, ...step.subSteps],
							}));
					},
				})}
				{...(ordering && dragSource)}
			>
				<Icon
					data-milestone={value.milestone}
					path={mdiFlagVariant}
					size={1}
				/>
				<StepTitle>
					Stap: {index} {value.name}
				</StepTitle>
				{!ordering && (
					<>
						<EditStepButton
							planId={plan.planId}
							step={value}
							setValue={setValue}
						/>
						<DeleteStepButton
							planId={plan.planId}
							step={value}
							remove={remove}
						/>
					</>
				)}
				{ordering && <Icon path={mdiArrowAll} size={1} />}
			</header>
			{mapSliced(value.subSteps, slice(setValue, 'subSteps'), (props) => (
				<SubStepRow
					key={props.value.subStepId}
					{...props}
					prependStep={prepend}
					plan={plan}
					stepId={value.stepId}
					ordering={ordering}
				/>
			))}
			<footer
				{...useDragSink({
					typesAllowed: ['step', 'subStep'],
					value,
					onDrop: (value, type) => {
						if (type === 'step') append(value as Step);
						else if (type === 'subStep')
							setValue((step) => ({
								...step,
								subSteps: [...step.subSteps, value as SubStep],
							}));
					},
				})}
			>
				<UpsertSubstepButton
					plan={plan}
					stepId={value.stepId}
					append={(newValue) =>
						slice(setValue, 'subSteps')([...value.subSteps, newValue])
					}
				/>
			</footer>
		</StepContainer>
	);
};

const SubStepRow: FC<
	SlicedElement<SubStep> & {
		prependStep: (value: Step) => void;
		plan: Plan;
		stepId: number;
		ordering: boolean;
	}
> = ({
	value,
	setValue,
	prepend,
	remove,
	prependStep,
	stepId,
	plan,
	ordering,
}) => {
	const dragSource = useDragSource({
		type: 'subStep',
		value,
		onDragEnd: (success) => success && remove(),
		dropEffect: 'move',
	});
	return (
		<div
			{...useDragSink({
				typesAllowed: ['subStep', 'step'],
				value,
				onDrop: (value, type) => {
					if (type === 'step') prependStep(value as Step);
					else if (type === 'subStep') prepend(value as SubStep);
				},
			})}
			{...(ordering && dragSource)}
		>
			<Icon data-milestone={value.milestone} path={mdiFlagVariant} size={1} />
			{ordering ? (
				<span>{value.name}</span>
			) : (
				<SubStepDetailLink
					stakeholderGroups={plan.stakeholderGroups}
					subStep={value}
				/>
			)}
			{!ordering && (
				<>
					<UpsertSubstepButton
						plan={plan}
						stepId={stepId}
						setValue={setValue}
						subStep={value}
					/>
					<DeleteSubStepButton
						planId={plan.planId}
						subStep={value}
						remove={remove}
						stepId={stepId}
					/>
				</>
			)}
			{ordering && <Icon path={mdiArrowAll} size={1} />}
		</div>
	);
};

const SubStepDetailLink: FC<{
	subStep: SubStep;
	stakeholderGroups: StakeholderGroup[];
}> = ({ subStep, stakeholderGroups }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<SidePanel title={subStep.name} open={open} setOpen={setOpen}>
				<SubStepDetails
					stakeholderGroups={stakeholderGroups}
					subStep={subStep}
				/>
			</SidePanel>
			<StyledSubStepTitle href={'#!'} onClick={() => setOpen(!open)}>
				{subStep.name}
			</StyledSubStepTitle>
		</>
	);
};

const AddStepButton: FC<{
	planId: number;
	addStep: (newValue: Step) => void;
}> = ({ planId, addStep }) => {
	const [open, setOpen] = useState(false);
	const [step, setStep] = useState<Step>({
		stepId: 0,
		name: '',
		subSteps: [],
		order: 0,
		milestone: false,
	});
	const authenticatedFetch = useAuthenticatedFetch();

	useEffect(() => {
		if (open)
			setStep({
				stepId: 0,
				name: '',
				subSteps: [],
				order: 0,
				milestone: false,
			});
	}, [setStep, open]);

	const submitStep = () =>
		authenticatedFetch(`${API_URL}/plan/${planId}/step`, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify(step),
		})
			.then((r) => r.json())
			.then((newStep) => addStep(newStep))
			.then(() => setOpen(false));

	return (
		<>
			<SidePanel
				title={'Nieuwe hoofdstap aanmaken'}
				open={open}
				setOpen={setOpen}
			>
				<StepForm step={step} setStep={setStep} onSubmit={submitStep}>
					<AppButton type={'submit'}>
						<AppIcon icon={<Icon path={mdiPlusBox} size={1} />} iconRight>
							Toevoegen
						</AppIcon>
					</AppButton>
				</StepForm>
			</SidePanel>
			<AppButton onClick={() => setOpen(true)}>
				<AppIcon
					icon={<Icon path={mdiBookmarkPlus} size={1} />}
					size={Size.Large}
					iconRight
				>
					HOOFDSTAP AANMAKEN
				</AppIcon>
			</AppButton>
		</>
	);
};

const EditStepButton: FC<{
	planId: number;
	step: Step;
	setValue: StateUpdater<Step>;
}> = ({ planId, step, setValue }) => {
	const [open, setOpen] = useState(false);
	const [newStep, setStep] = useState(step);
	const authenticatedFetch = useAuthenticatedFetch();

	async function editStep() {
		await authenticatedFetch(`${API_URL}/plan/${planId}/step`, {
			method: 'PUT',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify(newStep),
		})
			.then((r) => r.json())
			.then((r) => setValue(r))
			.then(() => setOpen(false));
	}

	return (
		<>
			<SidePanel title={'Hoofdstap Bewerken'} open={open} setOpen={setOpen}>
				<StepForm step={newStep} setStep={setStep} onSubmit={() => editStep()}>
					<AppButton type={'submit'}>
						<AppIcon icon={<Icon path={mdiContentSave} size={1} />} iconRight>
							Opslaan
						</AppIcon>
					</AppButton>
				</StepForm>
			</SidePanel>
			<SidePanelButton setOpen={setOpen} icon={mdiCog} />
		</>
	);
};

const DeleteStepButton: FC<{
	planId: number;
	step: Step;
	remove: () => void;
}> = ({ planId, step, remove }) => {
	const [open, setOpen] = useState(false);
	const authenticatedFetch = useAuthenticatedFetch();

	async function deleteStep() {
		await authenticatedFetch(`${API_URL}/plan/${planId}/step/${step.stepId}`, {
			method: 'DELETE',
		})
			.then(() => remove())
			.then(() => setOpen(false));
	}

	return (
		<>
			<SidePanel title={'Hoofdstap verwijderen'} open={open} setOpen={setOpen}>
				<h2>Weet je zeker dat je {step.name} wilt verwijderen?</h2>
				{step.subSteps.length > 0 && (
					<p>Je verwijdert daarmee ook alle bijbehorende substappen</p>
				)}
				<AppButton onClick={() => deleteStep()} noMargin>
					Verwijderen
				</AppButton>
			</SidePanel>
			<SidePanelButton setOpen={setOpen} icon={mdiDelete} />
		</>
	);
};

export const SidePanelButton: FC<{
	setOpen: (open: boolean) => void;
	icon: string;
}> = ({ setOpen, icon }) => (
	<div onClick={() => setOpen(true)}>
		<Icon path={icon} size={1} />
	</div>
);

const SubStepDetails: FC<{
	stakeholderGroups: StakeholderGroup[];
	subStep: SubStep;
}> = ({ subStep, stakeholderGroups }) => (
	<>
		{subStep.milestone && (
			<section>
				<AppIcon icon={<Icon path={mdiFlagVariant} size={1} />}>
					Deze Substap is een milestone
				</AppIcon>
			</section>
		)}
		{subStep.period && (
			<section>
				<h2>Periode</h2>
				{subStep.period}
				{/* <DateFormatter format={'dd-MM-yyyy'} date={subStep.dateTo} /> */}
			</section>
		)}
		{subStep.subStepStakeholderGroups?.length > 0 && (
			<section>
				<h2>Betrokken partijen</h2>
				<StyledStakeholderTable>
					<header>Groepsnaam</header>
					<header>Mandaat</header>
					<header>Domein</header>
					{subStep.subStepStakeholderGroups.map((group) => (
						<>
							<div>
								{
									stakeholderGroups.find(
										(x) => x.stakeholderGroupId == group.stakeholderGroupId
									)?.name
								}
							</div>
							<div>{group.mandate && MandateTranslations[group.mandate]}</div>
							<div>{group.domains}</div>
						</>
					))}
				</StyledStakeholderTable>
			</section>
		)}
		{subStep.domains && (
			<section>
				<h2>Besluitvorming</h2>
				{subStep.domains}
			</section>
		)}
	</>
);

const StepForm = ({
	step,
	setStep,
	onSubmit,
	children,
}: { onSubmit: () => void; children: React.ReactNode } & WithSetter<{
	step: Step;
}>) => (
	<form
		onSubmit={(e) => {
			e.preventDefault();
			onSubmit();
		}}
	>
		<AppInput
			label={'Naam'}
			value={step.name}
			onChange={(e) => slice(setStep, 'name')(e.target.value)}
			autoFocus
		/>
		<AppCheckbox
			label={'Milestone'}
			checked={step.milestone}
			onCheck={(milestone) => slice(setStep, 'milestone')(milestone)}
		>
			Deze hoofdstap is een milestone
		</AppCheckbox>
		{children}
	</form>
);

export default DefineParticipationPlan;
