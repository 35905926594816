import { FC } from 'react';
import styles from './AppCheckbox.module.css';
import { Size } from '../../types';
import { mdiCheckBold } from '@mdi/js';
import Icon from '@mdi/react';

const AppCheckbox: FC<{
	label?: string;
	checked?: boolean;
	onCheck?: (checked: boolean) => void;
	inputSize?: Size;
	children: React.ReactNode;
}> = ({ label, checked, onCheck, inputSize, children, ...props }) => (
	<div className={styles.AppCheckboxContainer}>
		{label && (
			<label htmlFor={'appCheckbox'} className={styles.AppInputLabel}>
				{label}
			</label>
		)}
		<div
			className={styles.AppCheckboxWrapper}
			onClick={() => onCheck?.(!checked)}
		>
			<input
				className={styles.HiddenCheckbox}
				type="checkbox"
				name="appCheckbox"
				checked={checked}
				readOnly
				{...props}
			/>
			<div
				className={`${styles.AppCheckbox} ${
					styles[`AppCheckbox${inputSize}`]
				}`}
				onClick={() => onCheck?.(!checked)}
			>
				<Icon
					className={`${styles.CheckMark} ${checked ? styles.Checked : ''}`}
					path={mdiCheckBold}
					size={1}
				/>
			</div>
			<span className={styles.AppCheckboxText}>{children}</span>
		</div>
	</div>
);

export default AppCheckbox;
