import { FC, ButtonHTMLAttributes } from 'react';
import styles from './AppButton.module.css';
import { Size } from '../../types';

const AppButton: FC<
	ButtonHTMLAttributes<HTMLButtonElement> & {
		size?: Size;
		noMargin?: boolean;
		white?: boolean;
		flat?: boolean;
		dotted?: boolean;
		noBackground?: boolean;
	}
> = ({
	size,
	noMargin,
	white,
	flat,
	className,
	children,
	noBackground,
	...props
}) => (
	<button
		role="presentation"
		className={
			`${styles.AppButton} ` +
			`${size ? styles[`AppButton${size}`] : ''} ` +
			`${noMargin ? styles.AppButtonNoMargin : ''} ` +
			`${white ? styles.AppButtonWhite : ''} ` +
			`${flat ? styles.AppButtonFlat : ''} ` +
			`${className ? className : ''}` +
			`${noBackground ? styles.NoBackground : ''}`
		}
		{...props}
	>
		{children}
	</button>
);

export default AppButton;
