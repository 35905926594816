import styles from './NavBar.module.css';
import { Link } from 'react-router-dom';
import AppIcon from '../AppIcon/AppIcon';
import { mdiAccountOutline } from '@mdi/js';
import { Size } from '../../types';
import Icon from '@mdi/react';
import { useContext } from 'react';
import { parseJWT, TokenContext } from '../../auth';

const tabs = [{ link: '/dashboard', text: 'Mijn projecten' }];

const NavBar = (): JSX.Element => (
	<nav className={styles.NavBar}>
		<div className={styles.NavBarItemsContainer}>
			<ul>
				{tabs.map((tab, i) => (
					<li key={i}>
						<Link to={tab.link}>{tab.text}</Link>
					</li>
				))}
			</ul>
		</div>
		<div className={styles.NavBarUserContainer}>
			<div className={styles.NavBarUser}>
				<AppIcon
					icon={<Icon path={mdiAccountOutline} size={1} />}
					size={Size.ExtraSmall}
				>
					{'Ingelogd als: ' + parseJWT(useContext(TokenContext).token!).name}
					<a
						href={`${
							process.env.REACT_APP_OIDC_BASE
						}/logout?post_logout_redirect_uri=${encodeURIComponent(
							process.env.REACT_APP_FRONTEND!
						)}`}
					>
						Logout
					</a>
				</AppIcon>
			</div>
		</div>
	</nav>
);

export default NavBar;
