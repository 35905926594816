import {
	mdiCheck,
	mdiChevronLeft,
	mdiChevronRight,
	mdiCog,
	mdiContentSave,
	mdiDelete,
	mdiFlagVariant,
	mdiPlusBox,
} from '@mdi/js';
import Icon from '@mdi/react';
import { FC, useEffect, useState } from 'react';
import { useAuthenticatedFetch } from '../../../../auth';
import AppCheckbox from '../../../../components/AppCheckbox/AppCheckbox';
import AppDropdown from '../../../../components/AppDropdown/AppDropdown';
import AppIcon from '../../../../components/AppIcon/AppIcon';
import AppInput, {
	AppTextarea,
} from '../../../../components/AppInput/AppInput';
import AppButton from '../../../../components/Button/AppButton';
import SidePanel from '../../../../components/SidePanel/SidePanel';
import slice, {
	mapSliced,
	StateUpdater,
	WithSetter,
} from '../../../../StateManagement';
import {
	Mandate,
	MandateTranslations,
	Plan,
	Size,
	SubStep,
	SubStepStakeholderGroup,
} from '../../../../types';
import { SidePanelButton } from '../ParticipationProcess';
import styles from './SubstepForm.module.css';

const API_URL = process.env.REACT_APP_BACKEND;

export const UpsertSubstepButton: FC<{
	plan: Plan;
	stepId: number;
	subStep?: SubStep;
	append?: (newValue: SubStep) => void;
	setValue?: StateUpdater<SubStep>;
}> = ({ plan, subStep, stepId, setValue, append }) => {
	const [open, setOpen] = useState(false);
	const [editedSubstep, setEditedSubstep] = useState<SubStep>(
		subStep ?? {
			subStepId: 0,
			name: '',
			subStepStakeholderGroups: [],
			order: 0,
			domains: '',
			milestone: false,
		}
	);

	useEffect(() => {
		if (open)
			setEditedSubstep(
				subStep ?? {
					subStepId: 0,
					name: '',
					subStepStakeholderGroups: [],
					order: 0,
					domains: '',
					milestone: false,
				}
			);
	}, [plan, subStep, open]);

	const authenticatedFetch = useAuthenticatedFetch();
	async function submitSubStep() {
		authenticatedFetch(
			`${API_URL}/plan/${plan.planId}/step/${stepId}/subStep`,
			{
				method: subStep ? 'PUT' : 'POST',
				headers: { 'Content-type': 'application/json' },
				body: JSON.stringify(editedSubstep),
			}
		)
			.then((r) => r.json())
			.then((r) => (subStep == null ? append?.(r) : setValue?.(r)))
			.then(() => setOpen(false));
	}

	return (
		<>
			<SidePanel
				title={subStep ? 'Substap aanpassen' : 'Nieuwe substap aanmaken'}
				open={open}
				setOpen={setOpen}
				wide={true}
			>
				<SubStepForm
					substep={editedSubstep}
					setSubstep={setEditedSubstep}
					onSubmit={() => submitSubStep()}
					isNew={subStep == null}
					plan={plan}
				/>
			</SidePanel>
			{subStep && <SidePanelButton setOpen={setOpen} icon={mdiCog} />}
			{!subStep && (
				<div
					className={styles.TableAddSubStepButton}
					onClick={() => setOpen(true)}
				>
					<Icon path={mdiFlagVariant} size={1} />
					NIEUWE SUBSTAP AANMAKEN
				</div>
			)}
		</>
	);
};

export const DeleteSubStepButton: FC<{
	planId: number;
	subStep: SubStep;
	remove: () => void;
	stepId: number;
}> = ({ planId, subStep, remove, stepId }) => {
	const [open, setOpen] = useState(false);
	const authenticatedFetch = useAuthenticatedFetch();

	async function deleteSubStep() {
		await authenticatedFetch(
			`${API_URL}/plan/${planId}/step/${stepId}/subStep/${subStep.subStepId}`,
			{
				method: 'DELETE',
			}
		)
			.then(() => remove())
			.then(() => setOpen(false));
	}

	return (
		<>
			<SidePanel title={'Substap Verwijderen'} open={open} setOpen={setOpen}>
				<h2>Weet je zeker dat je {subStep.name} wilt verwijderen?</h2>
				<AppButton
					onClick={() => {
						deleteSubStep();
					}}
					noMargin
				>
					Verwijderen
				</AppButton>
			</SidePanel>
			<SidePanelButton setOpen={setOpen} icon={mdiDelete} />
		</>
	);
};

const SubStepForm: FC<
	{
		onSubmit: () => void;
		isNew: boolean;
		plan: Plan;
	} & WithSetter<{ substep: SubStep }>
> = ({ substep, setSubstep, onSubmit, isNew, plan }) => {
	const [activePage, setActivePage] = useState(0);
	const forms = [
		{
			name: 'Doel',
			title: 'Doel',
			details: <SubStepDetails substep={substep} setSubstep={setSubstep} />,
			isReady: () => substep.name,
		},
		{
			name: 'Periode',
			title: 'Periode bepalen',
			details: <SubStepPeriod substep={substep} setSubstep={setSubstep} />,
			isReady: () => substep.period,
		},
		{
			name: 'Betrokken partijen',
			title: 'Betrokken partijen koppelen',
			details: (
				<SubStepStakeholders
					substep={substep}
					setSubstep={setSubstep}
					plan={plan}
				/>
			),
			isReady: () => substep.subStepStakeholderGroups.length > 0,
		},
		{
			name: 'Mandaten en domeinen',
			title: 'Toekennen mandaten en domeinen',
			details: (
				<SubStepMandates
					substep={substep}
					setSubstep={setSubstep}
					plan={plan}
				/>
			),
			isReady: () =>
				substep.subStepStakeholderGroups.some((x) => x.domains || x.mandate),
		},
		{
			name: 'Besluitvorming',
			title: 'Wijze van besluitvorming',
			details: (
				<SubStepDecisionMaking
					substep={substep}
					setSubstep={setSubstep}
					amountAllowed={200}
				/>
			),
			isReady: () => substep.domains,
		},
	];

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit();
			}}
		>
			<div className={styles.SubstepMenuContainer}>
				<ul>
					{forms.map((m, i) => {
						return (
							<li
								key={i}
								className={`${styles.SubstepMenuItem} ${
									activePage >= i ? styles.ActiveSubstepMenuItem : ''
								} `}
							>
								<a onClick={() => setActivePage(i)}>
									{' '}
									<span className={styles.Circle}>
										{m.isReady() && <Icon path={mdiCheck} size={0.8} />}
									</span>{' '}
									{m.name}
								</a>
							</li>
						);
					})}
					<li className={`${styles.SubstepMenuItem}`}>
						<a></a>
					</li>
				</ul>
			</div>

			<div className={styles.SubstepFormContainer}>
				{forms[activePage].details}
			</div>

			<div className={styles.SubStepButtons}>
				{activePage > 0 && (
					<AppButton
						type={'button'}
						onClick={() => setActivePage(activePage - 1)}
						className={styles.StepperButton}
					>
						<AppIcon
							icon={<Icon path={mdiChevronLeft} size={1} />}
							size={Size.ExtraSmall}
						>
							{forms[activePage - 1].title.toUpperCase()}
						</AppIcon>
					</AppButton>
				)}
				{activePage < forms.length - 1 && (
					<AppButton
						type={'button'}
						onClick={() => setActivePage(activePage + 1)}
						className={styles.StepperButton}
					>
						<AppIcon
							icon={<Icon path={mdiChevronRight} size={1} />}
							size={Size.ExtraSmall}
							iconRight
						>
							{forms[activePage + 1].title.toUpperCase()}
						</AppIcon>
					</AppButton>
				)}
				<AppButton type={'submit'} white>
					<AppIcon
						icon={<Icon path={isNew ? mdiPlusBox : mdiContentSave} size={1} />}
						iconRight
					>
						{isNew ? 'Toevoegen' : 'Opslaan'}
					</AppIcon>
				</AppButton>
			</div>
		</form>
	);
};

const SubStepDetails: FC<WithSetter<{ substep: SubStep }>> = ({
	substep,
	setSubstep,
}) => (
	<>
		<AppInput
			label={'Beschrijf het doel van de stap'}
			value={substep.name}
			onChange={(event) =>
				setSubstep({
					...substep,
					name: event.target.value,
				})
			}
			autoFocus
		/>
		<AppCheckbox
			label={'Milestone'}
			checked={substep.milestone}
			onCheck={(milestone) =>
				setSubstep({
					...substep,
					milestone: milestone,
				})
			}
		>
			Deze substap is een milestone
		</AppCheckbox>
	</>
);

const SubStepPeriod: FC<WithSetter<{ substep: SubStep }>> = ({
	substep,
	setSubstep,
}) => {
	useEffect(() => {
		console.log('kiwiw : ' + substep.period);
		slice(setSubstep, 'dateFrom')(substep.period);
	}, [substep.period, setSubstep]);

	console.log(substep.period);
	return (
		<AppInput
			label={'Vul de periode in'}
			value={substep.period}
			onChange={(event) =>
				setSubstep({
					...substep,
					period: event.target.value,
				})
			}
			autoFocus
		/>
	);
};

const SubStepStakeholders: FC<
	{ plan: Plan } & WithSetter<{ substep: SubStep }>
> = ({ substep, setSubstep, plan }) => {
	return (
		<>
			{plan.stakeholderGroups.map((group, i) => {
				return (
					<AppCheckbox
						key={i}
						label={i == 0 ? 'Betrokken partijen koppelen' : ''}
						inputSize={Size.Large}
						checked={
							substep.subStepStakeholderGroups?.some(
								(s) => s.stakeholderGroupId == group.stakeholderGroupId
							) ?? false
						}
						onCheck={(checked: boolean) => {
							const groups = checked
								? [
										...(substep.subStepStakeholderGroups || []),
										{
											stakeholderGroupId: group.stakeholderGroupId,
											substepId: substep.subStepId,
										} as unknown as SubStepStakeholderGroup,
									]
								: substep.subStepStakeholderGroups.filter(
										(s) => s.stakeholderGroupId != group.stakeholderGroupId
									);

							slice(setSubstep, 'subStepStakeholderGroups')(groups);
						}}
					>
						{group.name}
					</AppCheckbox>
				);
			})}
		</>
	);
};

const SubStepMandates: FC<
	{ plan: Plan } & WithSetter<{ substep: SubStep }>
> = ({ substep, setSubstep, plan }) => {
	console.log(substep);

	return (
		<div className={styles.StakeholderGroups}>
			<div className={styles.HeaderRow}>Groepsnaam</div>
			<div className={styles.HeaderRow}>Mandaat</div>
			<div className={styles.HeaderRow}>Domein</div>
			{substep.subStepStakeholderGroups?.length == 0 && (
				<div className={styles.EmptyRow}>
					Er zijn geen betrokken partijen gekoppeld.
				</div>
			)}
			{mapSliced(
				substep.subStepStakeholderGroups,
				slice(setSubstep, 'subStepStakeholderGroups'),
				(props) => (
					<>
						<div className={styles.Name}>
							{
								plan.stakeholderGroups.find(
									(x) => x.stakeholderGroupId == props.value.stakeholderGroupId
								)?.name
							}
						</div>
						<div>
							<AppDropdown
								// label={index == 0 ? 'Kies Mandaat' : ''}
								items={Object.values(Mandate).map((x) => ({
									value: x,
									name: MandateTranslations[x],
								}))}
								defaultItem={props.value.mandate}
								onSelect={(item) =>
									props.setValue({
										...props.value,
										mandate: item as Mandate,
									})
								}
							></AppDropdown>
						</div>
						<div>
							<AppInput
								label={''}
								value={props.value.domains || ''}
								onChange={(event) =>
									props.setValue({
										...props.value,
										domains: event.target.value,
									})
								}
							/>
						</div>
					</>
				)
			)}
		</div>
	);
};
//['Informeren', 'Raadplegen', 'Adviseren', 'Meebeslissen']
const SubStepDecisionMaking: FC<
	{ amountAllowed: number } & WithSetter<{ substep: SubStep }>
> = ({ amountAllowed, substep, setSubstep }) => (
	<>
		<AppTextarea
			label={'Omschrijf hoe eventuele besluiten in deze stap worden genomen'}
			value={substep.domains}
			onChange={(event) => {
				if (event.target.value.length <= amountAllowed)
					setSubstep({
						...substep,
						domains: event.target.value,
					});
			}}
			rows={10}
		/>
		<span>
			{amountAllowed - (substep.domains?.length ?? 0)} tekens resterend
		</span>
	</>
);

export default SubStepForm;
