import styled from 'styled-components';

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const RawStepContainer = ({
	children,
	...props
}: {
	children: React.ReactNode;
}) => (
	<section {...props}>
		<div>{children}</div>
	</section>
);

export const StepContainer = styled(RawStepContainer)`
	--dragging-border: 2px dotted grey;
	--insert-border: 4px solid var(--color-accent-teal-button);
	box-sizing: border-box;

	/* styling for draggable substeps */
	& > div > :is(div, header):is([draggable='true']) {
		cursor: grab;
		transition: height 0.1s;

		&:active {
			cursor: grabbing;
			border: var(--dragging-border);
		}

		&:hover {
			background: var(--bg-default);
		}
	}

	&:not(:last-child) {
		/* work around brain-dead global css */
		border-bottom: none;
	}

	/* styling for dragging a step */
	& > div:has(header[data-dragging='true']) {
		& > :not(header) {
			opacity: 0;
			height: 0;
			transition: height 0.1s;
			border: none;
			pointer-events: none;
		}

		& > footer {
			display: none;
		}

		& > header {
			border-top: none;
		}
	}

	& > div {
		box-shadow: rgb(0 0 0 / 16%) 0 2px 6px;
		border-radius: 0.2em;
		background-color: white;

		/* :has pseudo class is not yet supported for mozilla firefox */
		&:has(:not(footer)[data-hovering='step']) {
			border-top: var(--insert-border);
		}

		/* :has pseudo class is not yet supported for mozilla firefox */
		&:has(footer[data-hovering='step']) {
			border-bottom: var(--insert-border);
		}

		& > * {
			height: 3rem;
			padding: 0 1rem;
			align-items: center;
			display: flex;

			&:is(header) {
				font-weight: 600;
			}

			&:not(header) {
				border-top: var(--border-white);
				color: var(--color-accent-teal);
			}

			&:not(footer) {
				box-sizing: border-box;
			}

			&[data-hovering='subStep']:not(header) {
				border-top: var(--insert-border);
			}

			&[data-hovering='subStep']:is(header) + * {
				border-top: var(--insert-border);
			}

			&[data-dragging='true']:not(header) {
				border: var(--dragging-border);
			}

			& > :is(span, a) {
				padding: 0rem 1rem;
				flex-grow: 1;
			}

			& > svg {
				flex-shrink: 0;
			}

			& > div > svg {
				cursor: pointer;
			}

			& > svg[data-milestone='false'] {
				color: var(--color-disabled-grey);
			}

			& > svg[data-milestone='true'] {
				color: var(--color-accent-red);
			}
		}

		& > footer {
			padding: 1rem;

			& > * {
				width: 100%;
			}
		}
	}
`;

export const SegmentTitle = styled.h1`
	display: flex;
	height: 4rem;
	justify-content: space-between;
	align-items: center;
	border-bottom: var(--border-light-grey);
`;

export const StepTitle = styled.span`
	font-size: 1.5em;
	font-family: 'oranda-bt', sans-serif;
	font-weight: normal;
`;

export const StyledSubStepTitle = styled.a`
	text-decoration: underline;
	color: var(--color-accent-teal);
`;

export const StyledStakeholderTable = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	background-color: #fff;
	box-shadow: rgb(0 0 0 / 16%) 0 2px 6px;
	padding-bottom: 1em;
	border-radius: 0.2em;

	> div,
	header {
		padding: 0.5em;
		border-width: 0;
		border-bottom: var(--border-white);
	}

	header {
		font-weight: 600;
	}
`;
