import { Fragment, FC, useContext } from 'react';
import styles from './Breadcrumb.module.css';
import { Link } from 'react-router-dom';
import { PlanContext, PlanContextProps } from '../../contexts/PlanContext';
import { Segment, SegmentGroup } from '../../types';
import { segments } from '../../segments';

const Breadcrumb: FC<{
	activeSegmentGroup?: SegmentGroup;
	activeSegment?: Segment;
}> = ({ activeSegmentGroup, activeSegment }) => {
	const { plan } = useContext<PlanContextProps>(PlanContext);

	const crumbs = [
		plan && {
			relativePath: `plan/${plan.planId}`,
			title: plan?.title,
		},
		activeSegmentGroup &&
			activeSegment && {
				relativePath: `segment/${activeSegmentGroup}/${activeSegment}`,
				title: segments.find(
					(x) =>
						x.segmentGroup == activeSegmentGroup && x.segment == activeSegment
				)!.title,
			},
	].filter(function (crumb): crumb is Exclude<typeof crumb, undefined> {
		return crumb !== undefined;
	});

	return (
		<div className={styles.Breadcrumb}>
			<Link to={'/dashboard'}>Mijn projecten</Link>
			{crumbs.map((crumb, index) => (
				<Fragment key={index}>
					<span> / </span>
					<Link
						to={crumbs
							.slice(0, index + 1)
							.map((c) => `/${c.relativePath}`)
							.reduce((path, relativePath) => path + relativePath, '')}
					>
						{crumb.title}
					</Link>
				</Fragment>
			))}
		</div>
	);
};

export default Breadcrumb;
