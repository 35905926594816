import styles from './App.module.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Layout from './pages/Layout/Layout';
import PlanOverview from './pages/PlanOverview/PlanOverview';
import NotFound from './pages/NotFound/NotFound';
import { generateExpiry, TokenContext, useOidcImplicitToken } from './auth';
import { useStorage } from './StateManagement';

const testClaims =
	process.env.REACT_APP_OIDC_TEST === 'true'
		? () => ({
				iss: process.env.REACT_APP_OIDC_ISSUER!,
				aud: process.env.REACT_APP_OIDC_CLIENT_ID!,
				sub: 'test',
				name: 'Adviseur',
				...generateExpiry(),
			})
		: undefined;

const App = (): JSX.Element => {
	const navigate = useNavigate();
	const [nonce, setNonce] = useStorage<null | string>(
		'nonce',
		null,
		sessionStorage
	);
	const [redirectUri, setRedirectUri] = useStorage<null | string>(
		'redirectUri',
		null,
		sessionStorage
	);
	const { token, fetch } = useOidcImplicitToken(
		process.env.REACT_APP_OIDC_BASE
			? `${process.env.REACT_APP_OIDC_BASE}/authorize`
			: null!,
		{
			client_id: process.env.REACT_APP_OIDC_CLIENT_ID!,
			redirect_uri: `${process.env.REACT_APP_FRONTEND}/oauth-callback`,
			scope: 'openid profile',
		},
		{
			navigate,
			nonce,
			setNonce,
			redirectUri,
			setRedirectUri,
			testClaims,
		}
	);
	if (!token) return <></>;
	return (
		<div className={styles.App}>
			<TokenContext.Provider value={{ token, fetch }}>
				<Routes>
					<Route element={<Layout />}>
						<Route path={'/dashboard'} element={<Dashboard />} />
						<Route path={'/'} element={<Dashboard />} />
						<Route path={'plan/:planId/*'} element={<PlanOverview />} />
						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</TokenContext.Provider>
		</div>
	);
};

export default App;
