import { FC, ReactNode } from 'react';
import styles from './ToolTip.module.css';
import ReactTooltip from 'react-tooltip';

const ToolTip: FC<{
	id: number;
	content: ReactNode;
	clickable?: boolean;
	inline?: boolean;
	onlyIcon?: boolean;
	children: React.ReactNode;
}> = ({ id, content, clickable, children, inline, onlyIcon }) => (
	<>
		<div
			className={
				styles.ToolTip +
				' ' +
				(inline || onlyIcon ? styles.Inline : '') +
				' ' +
				(onlyIcon ? styles.OnlyIcon : '') +
				' ' +
				(clickable ? styles.Clickable : '')
			}
			data-for={`tooltip${id}`}
			data-tip={''}
			data-event-off={clickable ? 'click' : ''}
		>
			{children}
		</div>
		<ReactTooltip
			className={
				styles.ToolTipContainer + ' ' + (clickable ? styles.Clickable : '')
			}
			id={`tooltip${id}`}
			place={'top'}
			type={'dark'}
			effect={'solid'}
			globalEventOff={clickable ? 'click' : ''}
			multiline
			role="tooltip"
		>
			{content}
		</ReactTooltip>
	</>
);

export default ToolTip;
